import { FormControlLabel } from '@mui/material';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { Switch as S, SwitchProps as SProps } from 'src/components/Switch';
import { ILangKey, useLocalization } from 'src/lib/localization';

export type SwitchProps = Omit<SProps, 'name'> & {
  name: string;
  control: any;
  label?: ILangKey;
};

export const Switch: FC<SwitchProps> = ({
  name,
  control,
  sx,
  label,
  ...props
}) => {
  const { translator } = useLocalization();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange, ...rest } }) => (
        <FormControlLabel
          control={
            <S
              checked={Boolean(value)}
              {...rest}
              onChange={(e) => {
                onChange(Number(e.target.checked));
              }}
            />
          }
          label={label ? translator(label) : undefined}
        />
      )}
    />
  );
};
