import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UseMutationOptions } from 'src/lib/react-query';
import { notify } from 'src/lib/notify';
import { addEmployee } from './addEmployee';
import { IEmployee, IEmployeeSchema, EmployeeResponse } from '../../types';

interface useAddEmployeeOptions {
  config?: UseMutationOptions<IEmployee, IEmployeeSchema>;
}

export const useAddEmployee = (options: useAddEmployeeOptions = {}) => {
  const { config } = options;
  const client = useQueryClient();

  const mutationFn = async (employee: IEmployeeSchema) => {
    const res = await addEmployee(employee);

    client.setQueryData(
      ['employees', { page: '0', search: '', skip: '0', take: '25' }],
      (prev: EmployeeResponse = { total: 0, data: [] }) => ({
        total: ++prev.total,
        data: [res, ...prev.data],
      }),
    );
    notify.success('Added successfully');
    return res;
  };

  return useMutation({
    mutationFn,
    mutationKey: ['employees', { page: '0', search: '', skip: '0', take: '25' }],
    ...config,
  });
};
