import { Response } from 'src/types';
import { IUser, IUserSchema } from '../../types';
import { api } from 'src/lib/api';

type SigninResponse = Response<Omit<IUser, 'token'>>;

export async function signin(user: IUserSchema): Promise<IUser> {
  const response = await api.post<SigninResponse>('/auth/login', user);
  const { token, data } = response.data;
  return { token, ...data[0], isAuthenticated: true };
}
