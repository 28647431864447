import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import { Box, IconButton } from 'src/components/';
import { LanguageMenu } from 'src/components/LanguageMenu/LanguageMenu';
import { FC } from 'src/types';
import { Toolbar } from '@mui/material';
import { IoIosArrowForward } from 'react-icons/io';
import { IoIosArrowBack } from 'react-icons/io';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import { IoIosLogOut } from 'react-icons/io';
import { useLogOut } from 'src/features/auth';
type AppBarProps = {
  open: boolean;
  handleDrawerClose: () => void;
  handleDrawerOpen: () => void;
};

const AppBarDrawer = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export const AppBar: FC<AppBarProps> = ({ open, handleDrawerOpen, handleDrawerClose }) => {
  const theme = useTheme();
  const { mutateAsync: logOut, isLoading: loggingOut } = useLogOut();
  const handleLogOut = async () => {
    await logOut();
  };
  return (
    <AppBarDrawer position="fixed" sx={{ background: 'white' }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            toolTipTitle={open ? 'close' : 'open'}
            color="inherit"
            aria-label="open drawer"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            edge="start"
            sx={{
              borderRadius: '50%',
              background: theme.palette.primary.main,
              height: '2em',
              width: '2em',
              '&:hover': {
                background: theme.palette.primary.main,
              },
            }}
          >
            {open ? (
              theme.direction === 'rtl' ? (
                <IoIosArrowForward />
              ) : (
                <IoIosArrowBack />
              )
            ) : (
              <MenuIcon />
            )}
          </IconButton>
        </Box>
        {/* Language menu */}
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box
            sx={{
              borderRadius: '50%',
              width: 'fit-content',
            }}
          >
            <LanguageMenu />
          </Box>
          <Box>
            <IconButton onClick={handleLogOut} toolTipTitle={'logOut'} loading={loggingOut}>
              <IoIosLogOut />
            </IconButton>
          </Box>
        </Box>
      </Toolbar>
    </AppBarDrawer>
  );
};
