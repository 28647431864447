import { CardActions } from '@mui/material';
import MuiCard, { CardProps as MuiCardProps } from '@mui/material/Card';
import MuiCardContent, { CardContentProps as MuiCardContentProps } from '@mui/material/CardContent';
import { FC } from 'src/types';

type CardProps = MuiCardProps;

export const Card: FC<CardProps> = ({ sx = {}, ...props }) => {
  return <MuiCard sx={{ borderRadius: '12px', ...sx }} {...props} />;
};

type CardContentProps = MuiCardContentProps;

export const CardContent: FC<CardContentProps> = ({ sx = {}, ...props }) => {
  return (
    <MuiCardContent
      sx={{
        '&:last-child': { pb: 0 },
        padding: { xs: '1em!important', md: '1.7em!important' },
        ...sx,
      }}
      {...props}
    />
  );
};

export { CardActions };
