import React from 'react';
import { FC } from 'src/types';
import { ToastContainer } from 'react-toastify';

export type NotifyProviderProps = {
  chiledren?: React.ReactNode;
};

export const NotifyProvider: FC<NotifyProviderProps> = ({ chiledren }) => {
  return (
    <>
      {chiledren ? chiledren : null}
      <ToastContainer />
    </>
  );
};
