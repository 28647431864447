import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { CurrentLocationButton } from './CurrentLocationButton';
import { LocationMarker } from './Marker';
import 'leaflet/dist/leaflet.css';
import './style.css';
import { FC } from 'src/types';

type MapProps = {
  handleChange: (pos: [number, number]) => void;
  init?: [number, number];
  className?: string;
  style?: React.CSSProperties;
  showCurrentLocationButton?: boolean;
};

export const Map: FC<MapProps> = ({
  handleChange,
  init,
  className = '',
  style,
  showCurrentLocationButton,
}) => {
  const [markerPos, setMarkerPos] = useState<[number, number]>(
    init || [33.262751244441105, 44.41386222839356],
  );
  const mapRef = useRef<any>(null);
  const liveLocationMarkerRef = useRef<any>();

  const handleShowMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (location) => {
          const { latitude, longitude } = location.coords;
          // setPositionLive([latitude, longitude]);
          setMarkerPos([latitude, longitude]);
          if (mapRef.current) {
            mapRef.current.setView([latitude, longitude], 16);
          }

          // Update the position of the live location marker
          if (liveLocationMarkerRef.current) {
            liveLocationMarkerRef.current.setLatLng([latitude, longitude]);
          }
        },
        (error) => {
          console.error('Error getting location:', error.message);
        },
      );
    } else {
      console.error('Geolocation is not supported by your browser.');
    }
  };

  useEffect(() => {
    if (!init) {
      handleShowMyLocation();
    }
  }, []);

  useEffect(() => {
    if (handleChange) {
      handleChange(markerPos);
    }
  }, [markerPos]);

  return (
    <div className={`map-container ${className}`} style={style}>
      <MapContainer
        center={markerPos}
        zoom={13}
        style={style}
        className={`map ${className}`}
        ref={mapRef}
      >
        <TileLayer url="https://api.maptiler.com/maps/streets-v2/{z}/{x}/{y}.png?key=BfeEUK6DpsOHYVNLPM6w" />
        <LocationMarker onMove={setMarkerPos} pos={markerPos} />
      </MapContainer>
      {showCurrentLocationButton && (
        <CurrentLocationButton handleShowMyLocation={handleShowMyLocation} />
      )}
    </div>
  );
};
