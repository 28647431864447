import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UseMutationOptions } from 'src/lib/react-query';
import { notify } from 'src/lib/notify';
import { deactivateEmployee } from './deactivateEmployee';
import { IEmployee } from '../../types';
import { Translator } from 'src/lib/localization';
import { useQueryParams } from 'src/hooks';
import { IFilter } from 'src/types';

interface useDeactivateEmployeeOptions {
  config?: UseMutationOptions<IEmployee, string>;
}

export const useDeactivateEmployee = (options: useDeactivateEmployeeOptions = {}) => {
  const { config } = options;
  const client = useQueryClient();
  const { getParam } = useQueryParams<IFilter>({
    defaultInit: { page: '0' },
  });

  const mutationFn = async (id: string) => {
    try {
      const res = await deactivateEmployee(id);

      // update banners in query client (cache data)
      client.invalidateQueries({
        predicate: (query: any) =>
          query.queryKey[0] === 'employees' &&
          query.queryKey[1] &&
          query.queryKey[1]?.page === getParam('page'),
      });

      notify.success(<Translator textKey="deactivateSuccess" />);
      return res;
    } catch (error: any) {
      notify.error(error?.response?.data?.errors[0]);
    }
  };

  return useMutation({
    mutationFn: mutationFn as any,
    mutationKey: ['employees'],
    ...config,
  });
};
