import { Route, Routes } from 'react-router-dom';
import { lazyImport } from 'src/utils';
const { Categories } = lazyImport(() => import('./pages'), 'Categories');
const { CategoryDetailes } = lazyImport(() => import('./pages'), 'CategoryDetailes');

export const CategoriesRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Categories />} />
      <Route path="/:id" element={<CategoryDetailes />} />
    </Routes>
  );
};
