import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { FC } from 'src/types';
import styles from './style.module.scss';
import { cx } from 'src/utils';

export type ButtonProps = LoadingButtonProps;

export const Button: FC<ButtonProps> = ({ className, ...props }) => {
  return <LoadingButton className={cx(styles.button, className)} {...props} />;
};
