import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UseMutationOptions } from 'src/lib/react-query';
import { notify } from 'src/lib/notify';
import { updateUser } from './updateUser';
import { IUser } from '../../types';
import { useLocalization } from 'src/lib/localization';

interface UseUpdateUserOptions {
  config?: UseMutationOptions<IUser>;
}

export const useUpdateUser = (options: UseUpdateUserOptions = {}) => {
  const { config } = options;
  const client = useQueryClient();
  const { translator } = useLocalization();

  const mutationFn = async (user: IUser) => {
    try {
      const res = await updateUser(user);

      client.invalidateQueries({
        predicate: (query: any) => query.queryKey.includes('user'),
      });
      notify.success(translator('editSuccess'));
      return res;
    } catch (error: any) {
      notify.error(error?.response?.data?.errors[0]);
    }
  };

  return useMutation({
    mutationFn: mutationFn as any,
    mutationKey: ['user'],
    ...config,
  });
};
