import { Theme } from '@mui/material';
import { styled } from '@mui/material/styles';

const openedMixin = (theme: Theme) => ({
  width: '270px',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `0px`,
});

export const Drawer: any = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }: any) => ({
    width: '270px',
    // minWidth: '4em',
    height: '100vh',
    overflow: 'scroll',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    paddingTop: '5em',
    zIndex: 1000,
    position: 'fixed',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
