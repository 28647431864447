import { useQuery } from '@tanstack/react-query';
import { UseQueryOptions } from 'src/lib/react-query';
import { useQueryParams } from 'src/hooks';
import { IFilter } from 'src/types';

type UsePaginationOptions<TData = any> = Omit<UseQueryOptions<TData>, 'queryFn'> & {
  apiFn: (filters: IFilter) => Promise<TData>;
};

/**
 * Handle pagination for get request. works as wrraper,
 * pass queryKey and apiFn which is a function recive filter object
 */
export const usePagination = <TData = any,>(options: UsePaginationOptions<TData>) => {
  const { apiFn, queryKey, ...rest } = options;
  const { getParam } = useQueryParams<IFilter>({
    defaultInit: { page: '0', take: '25', skip: '0' },
  });

  return useQuery({
    queryKey: [
      queryKey,
      {
        page: getParam('page'),
        take: getParam('take'),
        skip: getParam('skip'),
        search: getParam('search') || '',
      },
    ],
    queryFn: () =>
      apiFn({
        page: getParam('page'),
        take: getParam('take'),
        skip: getParam('skip'),
        search: getParam('search'),
      }),
    keepPreviousData: true,
    ...rest,
  });
};
