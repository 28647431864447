import React, { useState } from 'react';
import { FC } from 'src/types';
import { Box } from 'src/components';
import { SlideBar } from './SlideBar/SlideBar';
import { AppBar } from './AppBar';
import { Container } from '../Container/Container';

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', position: 'relative' }}>
      <AppBar
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
      />
      <SlideBar open={open} />
      <Box component="main" sx={{ width: '100%' }}>
        {children}
      </Box>
    </Box>
  );
};
