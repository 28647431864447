/* eslint-disable react-hooks/rules-of-hooks */
import {
  GridColDef,
  GridColumnActions,
  Text,
  EditAction,
  DeleteAction,
  AlertDialog,
} from 'src/components';
import { Role } from '../../../types';
import { useLocalization } from 'src/lib/localization';
import { useState } from 'react';
import { Avatar } from '@mui/material';
import { useDeleteRole } from 'src/features/roles/api/deleteRole';
type Options = {
  handleEdit: (role: Role) => void;
};
export const RolesColumns = ({ handleEdit }: Options): GridColDef<Role>[] => {
  const { translator, code } = useLocalization();
  return [
    {
      headerClassName: 'grid-header',
      disableColumnMenu: true,
      sortable: false,
      field: 'name',
      headerName: translator('name'),
      flex: 1,
      minWidth: 150,
      renderCell: ({ row }) => {
        return <Text>{row.name}</Text>;
      },
    },
    {
      headerClassName: 'grid-header',
      disableColumnMenu: true,
      sortable: false,
      field: 'created_at',
      headerName: translator('created_at'),
      flex: 1,
      minWidth: 110,
      renderCell: ({ row }) => {
        return <Text>{new Date(row.created_at).toLocaleString()}</Text>;
      },
    },
    {
      headerClassName: 'grid-header',
      disableColumnMenu: true,
      sortable: false,
      field: 'actions',
      headerName: translator('actions'),
      flex: 1,
      minWidth: 80,
      renderCell: ({ row }) => {
        const [open, setOpen] = useState(false);

        const { mutate: deleteExpense, isLoading: deletingExpense } = useDeleteRole();

        return (
          <GridColumnActions>
            <EditAction
              handleClick={() => {
                handleEdit(row);
              }}
            />
            <DeleteAction handleClick={() => setOpen(true)} loading={deletingExpense} />
            <AlertDialog
              title="deleteExpense"
              open={open}
              handleClose={() => setOpen(false)}
              action={() => deleteExpense(row.id)}
            />
          </GridColumnActions>
        );
      },
    },
  ];
};
