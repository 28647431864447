import { useEffect, useState } from 'react';
import { useQueryParams } from 'src/hooks';
import { TextField } from '../';
import { FC, IFilter } from 'src/types';
import { ILangKey, useLocalization } from 'src/lib/localization';

type SearchProps = {
  label?: ILangKey;
  placeholder?: ILangKey;
};

export const Search: FC<SearchProps> = ({ label = 'search', placeholder = 'search' }) => {
  const { setParams, getParam } = useQueryParams<IFilter>();
  const [search, setSearch] = useState(getParam('search') || '');
  const { translator } = useLocalization();

  const handleSearch = (searchValue: string) => {
    setParams({ page: '0', take: '25', skip: '0', search: searchValue });
  };

  useEffect(() => {
    if (!getParam('search')) {
      setSearch('');
    }
  }, [getParam('search')]);

  return (
    <>
      <TextField
        label={translator(label)}
        placeholder={translator(placeholder)}
        id="search"
        type="text"
        autoComplete="off"
        name="search"
        value={search}
        defaultValue={''}
        autoFocus
        onChange={(e) => {
          setSearch(e.target.value);
          handleSearch(e.target.value);
        }}
        inputProps={{
          autoComplete: 'off',
          form: {
            autocomplete: 'off',
          },
        }}
      />
      <TextField
        label="Search"
        placeholder="Search"
        id="ii"
        type="text"
        autoComplete="off"
        name="n"
        value=""
        defaultValue={''}
        sx={{ display: 'none' }}
      />
    </>
  );
};
