import { Text, TextProps } from '../Text';
import { cx } from 'src/utils';
import { FC } from 'src/types';
import styles from './styles.module.scss';
import { ILangKey, Translator } from 'src/lib/localization';

export type TitleProps = Omit<TextProps, 'children'> & {
  title: ILangKey;
  type?: 'main-title' | 'secondary-title';
};

export const Title: FC<TitleProps> = ({ title, className, type = 'main-title', ...props }) => {
  return (
    <Text
      className={cx(
        styles.title,
        className,
        type === 'main-title' ? styles['main-title'] : styles['secondary-title'],
      )}
      variant="h5"
      component="h2"
      {...props}
    >
      <span>
        <Translator textKey={title} />
      </span>
      <span className={cx(styles.underline)}></span>
    </Text>
  );
};
