import { FC } from 'react';
import { Controller, useFormState } from 'react-hook-form';
import { ILangKey, useLocalization } from 'src/lib/localization';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Box } from 'src/components/Box';
import { MenuItem as MuiMenuItem, Select as MuiSelect } from '@mui/material';
import { Text } from 'src/components/Text';
import { ErrorMessage } from 'src/components/ErrorMessage';
import { SelectProps as MuiSelectProps } from '@mui/material/Select';

export type SelectProps = Omit<MuiSelectProps, 'name'> & {
  children: React.ReactNode;
  name: string;
  control: any;
  label?: ILangKey;
};

export const Select: FC<SelectProps> = ({ name, control, label, children, ...props }) => {
  const { errors } = useFormState({ control, name: name });
  const error = errors[name]?.message;
  const { translator } = useLocalization();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {label ? translator(label) : undefined}
            </InputLabel>
            <MuiSelect
              fullWidth
              labelId="demo-simple-select-label"
              size="small"
              label={label ? translator(label) : undefined}
              id="demo-simple-select"
              {...props}
              {...field}
            >
              {children}
            </MuiSelect>
            {error ? (
              <Text color="error">
                <ErrorMessage error={error as any} />
              </Text>
            ) : null}
          </FormControl>
        </Box>
      )}
    />
  );
};

export const MenuItem = MuiMenuItem;
