import { Response } from 'src/types';
import { IEmployeeSchema, IEmployee } from '../../types';
import { privateApi } from 'src/lib/api';

export async function editEmployee({
  id,
  employee,
}: {
  id: string;
  employee: FormData;
}): Promise<IEmployee> {
  const api = privateApi();
  const response = await api.post<Response<IEmployee>>(`/employee/user/${id}`, employee, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  const { data } = response.data;
  return data[0];
}
