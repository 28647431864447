import { getLang } from './utils';
import { useLocalization } from './LocalizationContext';
import { ILangKey } from './lang';
import { FC } from 'src/types';

export type TranslatorProps = {
  textKey: ILangKey;
};

export const Translator: FC<TranslatorProps> = ({ textKey }) => {
  const { code } = useLocalization();
  const language = getLang(code);
  
  return <>{language[textKey] || null}</>;
};
