import { Response } from 'src/types';
import { IEmployee, IEmployeeSchema } from '../../types';
import { privateApi } from 'src/lib/api';

export async function addEmployee(employee: IEmployeeSchema): Promise<IEmployee> {
  const api = privateApi();
  const response = await api.post<Response<IEmployee>>('/employee/user', employee);
  const { data } = response.data;
  return data[0];
}
