import React from 'react';
import { FC } from 'src/types';
import { CircularProgress } from '@mui/material';

export type HandlePermissionsLoading = {
  isLoading: boolean;
  children: React.ReactNode;
};

// TODO: Change its name to handle status and make it handle error status with loading status
/**
 * return loading feedback when isLoading true, otherwise returns passed chiledren
 */
export const HandlePermissionsLoading: FC<HandlePermissionsLoading> = ({ isLoading, children }) => {
  if (isLoading) {
    return <CircularProgress />;
  }

  return <>{children}</>;
};
