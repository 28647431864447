import { Response } from 'src/types';
import { IUser } from '../../types';
import { privateApi } from 'src/lib/api';

type GetInfoResponse = Response<Omit<IUser, 'token'>>;

export async function getInfo(): Promise<Omit<IUser, 'token'>> {
  const api = privateApi();
  const response = await api.get<GetInfoResponse>('/auth/info');
  const { data } = response.data;
  return { ...data[0], isAuthenticated:true };
}
