import { Box, List, Text } from 'src/components';
import { Link } from 'src/lib/router';
import { Drawer } from '../Drawer';
import { AiOutlineHome } from 'react-icons/ai';
import { AiFillHome } from 'react-icons/ai';
import { IoLocationOutline } from 'react-icons/io5';
import { IoLocationSharp } from 'react-icons/io5';
import { FC } from 'src/types';
import { NavItem } from './NavItem';
import { cx } from 'src/utils';
import styles from './styles.module.scss';
import { INavItem } from './types';
import { TbCategory } from 'react-icons/tb';
import { TbCategoryFilled } from 'react-icons/tb';
import { IoImagesSharp } from 'react-icons/io5';
import { IoImagesOutline } from 'react-icons/io5';
import { BsBoxSeamFill } from 'react-icons/bs';
import { BsBoxSeam } from 'react-icons/bs';
import { IoNotificationsOutline } from 'react-icons/io5';
import { IoNotifications } from 'react-icons/io5';
import { RiUser2Line } from 'react-icons/ri';
import { RiUser2Fill } from 'react-icons/ri';
import { RiMoneyDollarBoxLine } from 'react-icons/ri';
import { RiMoneyDollarBoxFill } from 'react-icons/ri';
import { IoLockOpenOutline } from 'react-icons/io5';
import { IoLockOpenSharp } from 'react-icons/io5';
import { AiOutlineShopping } from 'react-icons/ai';
import { AiFillShopping } from 'react-icons/ai';
import { useAuth } from 'src/lib/auth';
import { Avatar, Button } from '@mui/material';
import { FaRegComments } from 'react-icons/fa6';
import { FaComments } from 'react-icons/fa';
type SlideBarProps = {
  open: boolean;
};

const navList: INavItem[] = [
  {
    title: 'home',
    link: '/',
    Icon: AiOutlineHome,
    ActiveIcon: AiFillHome,
  },
  {
    title: 'provinces',
    link: '/provinces',
    Icon: IoLocationOutline,
    ActiveIcon: IoLocationSharp,
  },
  {
    title: 'categories',
    link: '/categories',
    Icon: TbCategory,
    ActiveIcon: TbCategoryFilled,
  },
  { title: 'employees', link: '/employees', Icon: RiUser2Line, ActiveIcon: RiUser2Fill },
  { title: 'customers', link: '/customers', Icon: RiUser2Line, ActiveIcon: RiUser2Fill },
  { title: 'banners', link: '/banner', Icon: IoImagesOutline, ActiveIcon: IoImagesSharp },
  {
    title: 'items',
    link: '/items',
    Icon: BsBoxSeam,
    ActiveIcon: BsBoxSeamFill,
  },
  {
    title: 'orders',
    link: '/orders',
    Icon: AiOutlineShopping,
    ActiveIcon: AiFillShopping,
  },
  {
    title: 'notifications',
    link: '/notifications',
    Icon: IoNotificationsOutline,
    ActiveIcon: IoNotifications,
  },
  {
    title: 'expenses',
    link: '/expenses',
    Icon: RiMoneyDollarBoxLine,
    ActiveIcon: RiMoneyDollarBoxFill,
  },
  {
    title: 'roles',
    link: '/roles',
    Icon: IoLockOpenOutline,
    ActiveIcon: IoLockOpenSharp,
  },
  {
    title: 'feedbacks',
    link: '/feedbacks',
    Icon: FaRegComments,
    ActiveIcon: FaComments,
  },
];

export const SlideBar: FC<SlideBarProps> = ({ open }) => {
  const { user } = useAuth();
  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        zIndex: 100,
        backgroundColor: 'white',
      }}
    >
      <Link to={'/profile'} className={cx(styles['profile-link'])}>
        <Box className={cx(styles['profile-wrapper'])} component={Button}>
          <Avatar src={user?.profile_pic} sx={{ width: 50, height: 50 }} />
          <Text className={cx(styles['name'])}>{user?.first_name + ' ' + user?.last_name}</Text>
        </Box>
      </Link>
      <List className={cx(styles['nav-list'])}>
        {navList.map((item) => (
          <NavItem {...item} />
        ))}
      </List>
    </Drawer>
  );
};
