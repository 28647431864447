import { privateApi } from 'src/lib/api';
import { ICategory, ICategorySchema, ICategoryResponse } from '../../types';
export async function editCategory(
  category_id: string,
  category: ICategorySchema,
): Promise<ICategory> {
  const api = privateApi();
  const response = await api.put<ICategoryResponse>(`/employee/category/${category_id}`, category);
  const { data } = response.data;
  return data[0];
}
