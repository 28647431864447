import FormControlLabel from '@mui/material/FormControlLabel';
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';
import { FC } from 'src/types';
import { ILangKey, useLocalization } from 'src/lib/localization';

type CheckboxProps = MuiCheckboxProps & {
  label?: ILangKey;
  required?: boolean;
};

export const Checkbox: FC<CheckboxProps> = ({ label, required = false, ...props }) => {
  const { translator } = useLocalization();

  return (
    <FormControlLabel
      required={required}
      control={<MuiCheckbox {...props} />}
      label={label ? translator(label) : ''}
    />
  );
};
