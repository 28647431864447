import React from 'react';
import styles from './styles.module.scss';
import { Box } from '../Box';
import { FC } from 'src/types';
import { cx } from 'src/utils';

export type PageProps = {
  children: React.ReactNode;
};

export const Page: FC<PageProps> = ({ children }) => {
  return <Box className={cx(styles.page)}>{children}</Box>;
};
