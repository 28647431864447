import React from 'react';
import { ILangKey, useLocalization } from 'src/lib/localization';
export interface ValidationError {
  /** key into translation file */
  key: ILangKey;

  /** token values */
  values?: { [key: string]: any };
}
export interface ErrorMessageProps {
  error?: ValidationError | string;
}

export const ErrorMessage = ({ error }: ErrorMessageProps) => {
  const { translator } = useLocalization();

  if (!error) {
    return null;
  } else if (typeof error === 'string') {
    return <>{error}</>;
  } else {
    const { key, values } = error;
    return <>{translator(key, values)}</>;
  }
};
