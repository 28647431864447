import { Routes, Route } from 'src/lib/router';
import { AuthRoutes } from 'src/features/auth';
import { ProvinceRoutes } from 'src/features/province';
import { PrivateRoute } from 'src/lib/auth';
import { lazyImport } from 'src/utils';
import { CategoriesRoutes } from 'src/features/categories';
import { EmployeesRoutes } from 'src/features/adminsManagement';
import { BannerRoutes } from 'src/features/banner';
import { ItemsRoutes } from 'src/features/items';
import { OrdersRoutes } from 'src/features/orders';
import { NotificationsRoutes } from 'src/features/notifications';
import { ExpensesRoutes } from 'src/features/expenses';
import { RolesRoutes } from 'src/features/roles';
import { ProfileRoutes } from 'src/features/profile';
import { CustomersRoutes } from 'src/features/customers';
import { FeedbackRoutes } from 'src/features/feedbacks';
const { Home } = lazyImport(() => import('src/features/home'), 'Home');
const { ResetPassword } = lazyImport(() => import('src/features/resetPassword'), 'ResetPassword');
const { VerifyEmail } = lazyImport(() => import('src/features/verifyEmail'), 'VerifyEmail');
const { DeleteAccount } = lazyImport(() => import('src/features/deleteVerify'), 'DeleteAccount');
const { DeleteVerify } = lazyImport(() => import('src/features/deleteVerify'), 'DeleteVerify');
const { NotFound } = lazyImport(() => import('src/features/NotFound'), 'NotFound');
export const MainRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      <Route
        path="/provinces/*"
        element={
          <PrivateRoute>
            <ProvinceRoutes />
          </PrivateRoute>
        }
      />
      <Route
        path="/employees/*"
        element={
          <PrivateRoute>
            <EmployeesRoutes />
          </PrivateRoute>
        }
      />

      <Route
        path="/categories/*"
        element={
          <PrivateRoute>
            <CategoriesRoutes />
          </PrivateRoute>
        }
      />

      <Route
        path="/items/*"
        element={
          <PrivateRoute>
            <ItemsRoutes />
          </PrivateRoute>
        }
      />

      <Route
        path="/banner/*"
        element={
          <PrivateRoute>
            <BannerRoutes />
          </PrivateRoute>
        }
      />
      <Route
        path="/orders/*"
        element={
          <PrivateRoute>
            <OrdersRoutes />
          </PrivateRoute>
        }
      />
      <Route
        path="/notifications/*"
        element={
          <PrivateRoute>
            <NotificationsRoutes />
          </PrivateRoute>
        }
      />
      <Route
        path="/expenses/*"
        element={
          <PrivateRoute>
            <ExpensesRoutes />
          </PrivateRoute>
        }
      />
      <Route
        path="/roles/*"
        element={
          <PrivateRoute>
            <RolesRoutes />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile/*"
        element={
          <PrivateRoute>
            <ProfileRoutes />
          </PrivateRoute>
        }
      />
      <Route
        path="/customers/*"
        element={
          <PrivateRoute>
            <CustomersRoutes />
          </PrivateRoute>
        }
      />
      <Route
        path="/feedbacks/*"
        element={
          <PrivateRoute>
            <FeedbackRoutes />
          </PrivateRoute>
        }
      />
      <Route path="/auth/*" element={<AuthRoutes />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/deleteAccount" element={<DeleteAccount />} />
      <Route path="/delete-verify" element={<DeleteVerify />} />
      <Route path="/verify" element={<VerifyEmail />} />
      <Route path="/*" element={<NotFound />} />
      {/* <Route path="/*" element={<Navigate to="/auth/signin" />} /> */}
    </Routes>
  );
};
