import { useQueryClient } from '@tanstack/react-query';
import { IUser } from 'src/features/auth/types';

/**
 * This hook use to return user data
 */
export const useAuth = () => {
  const client = useQueryClient();
  const data = client.getQueryData<IUser>(['user']);
  return { user: data };
};
