import { getCategories } from './getCategories';
import { UseQueryOptions } from 'src/lib/react-query';
import { usePagination } from 'src/hooks';
import { IGetCategoriesResponse } from '../../types';

interface UseGetProvinceOptions {
  config?: UseQueryOptions<IGetCategoriesResponse>;
}

export const useGetCategories = (options: UseGetProvinceOptions = {}) => {
  const { config } = options;
  return usePagination<IGetCategoriesResponse>({
    queryKey: 'categories',
    apiFn: getCategories,
    ...config,
  });
};
