import * as React from 'react';
import { Menu, MenuItem, IconButton } from 'src/components';
import styles from './styles.module.scss';
import { cx } from 'src/utils';
import { Translator, useLocalization } from 'src/lib/localization';
import { useAuth } from 'src/lib/auth';
import { useUpdateUser } from 'src/features/auth/api/updateUser';

export const LanguageMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { translator, code, changeLanguage } = useLocalization();
  const { user } = useAuth();
  const { mutateAsync: updateUser } = useUpdateUser();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        toolTipTitle={code === 'en' ? 'english' : 'arabic'}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={cx(styles['icon-button'])}
      >
        <img
          src={`${code === 'ar' ? '/assets/iq.svg' : '/assets/gb.svg'}`}
          alt="language"
          className={cx(styles['icon'])}
        />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            changeLanguage('en');
          }}
        >
          <Translator textKey="english" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeLanguage('ar');
          }}
        >
          <Translator textKey="arabic" />
        </MenuItem>
      </Menu>
    </div>
  );
};
