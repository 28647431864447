import { IGetRoleByIdResponse, GetRoleById } from '../../types';
import { privateApi } from 'src/lib/api';

export async function getRoleById(id: string): Promise<GetRoleById> {
  const api = privateApi();

  const response = await api.get<IGetRoleByIdResponse>(`/employee/roles/${id}`);
  const { data } = response.data;
  return data[0];
}
