import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UseMutationOptions } from 'src/lib/react-query';
import { notify } from 'src/lib/notify';
import { addCategory } from './addCategory';
import { ICategory, ICategoryDetailes, ICategorySchema, IGetCategoriesResponse } from '../../types';
import { Translator } from 'src/lib/localization';
import { useParams } from 'src/lib/router';

interface UseAddCategoryOptions {
  config?: UseMutationOptions<ICategory, ICategorySchema>;
}

export const useAddCategory = (options: UseAddCategoryOptions = {}) => {
  const { config } = options;
  const client = useQueryClient();
  const params = useParams();

  const mutationFn = async (categoryData: ICategorySchema) => {
    const res = await addCategory(categoryData);

    // update categories in query client (cache data)
    client.setQueryData(
      ['categories', { page: '0', search: null, skip: '0', take: '25' }],
      (prev: IGetCategoriesResponse = { total: 0, data: [] }) => ({
        total: ++prev.total,
        data: [res, ...prev.data],
      }),
    );

    // update cash for category detailes for matched id [it works only when add sub category in category detailes page]
    if (params?.id) {
      client.setQueryData(
        ['categories', { id: params.id }],
        (prev: ICategoryDetailes = {} as ICategoryDetailes) => ({
          ...prev,
          sub_category: [res, ...prev.sub_category],
        }),
      );
    }

    notify.success(<Translator textKey="addSuccess" />);
    return res;
  };

  return useMutation({
    mutationFn,
    mutationKey: ['categories'],
    ...config,
  });
};
