import React, { useEffect } from 'react';
import { useGetInfo } from 'src/features/auth';
import { useNavigate, useLocation } from 'src/lib/router';
import { FC } from 'src/types';
import { Loading } from 'src/components';

export type CheckAuthProps = {
  children: React.ReactNode;
};

/**
 * This component executes only once when user first inter to the app, it
 * will check if the user is logged in and have valid token then it will
 * navigate user to home page, otherwise navigate to sign in page
 */
export const CheckAuth: FC<CheckAuthProps> = ({ children }) => {
  const navigate = useNavigate();
  const info = useGetInfo({});
  const location = useLocation();

  useEffect(() => {
    if (info.isSuccess) {
      navigate(location.pathname, { replace: true });
    }

    if (info.isError) {
      if (
        location.pathname === '/reset-password' ||
        location.pathname === '/verify' ||
        location.pathname === '/deleteAccount' ||
        location.pathname === '/delete-verify'
      ) {
        return;
      } else {
        navigate('/auth/signin', { replace: true });
      }
    }
  }, [info.isSuccess, info.isError]);

  if (info.isFetching) {
    return <Loading />;
  }

  return <>{children}</>;
};
