import { Route, Routes } from 'react-router-dom';
import { lazyImport } from 'src/utils';
const { Orders } = lazyImport(() => import('./pages'), 'Orders');
const { OrderDetailes } = lazyImport(() => import('./pages'), 'OrderDetailes');
export const OrdersRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Orders />} />
      <Route path="/:id" element={<OrderDetailes />} />
    </Routes>
  );
};
