import { Route, Routes } from 'react-router-dom';
import { lazyImport } from 'src/utils';
const { Items } = lazyImport(() => import('./pages'), 'Items');
const { ItemDetailes } = lazyImport(() => import('./pages'), 'ItemDetailes');

export const ItemsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Items />} />
      <Route path="/:id" element={<ItemDetailes />} />
    </Routes>
  );
};
