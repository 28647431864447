import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UseMutationOptions } from 'src/lib/react-query';
import { notify } from 'src/lib/notify';
import { deleteCategory } from './deleteCategory';
import { ICategory, ICategoryDetailes } from '../../types';
import { Translator } from 'src/lib/localization';
import { useQueryParams } from 'src/hooks';
import { IFilter } from 'src/types';
import { useParams } from 'src/lib/router';
import { deleteOne } from 'src/utils';

interface UseDeleteCategoryOptions {
  config?: UseMutationOptions<ICategory, string>;
}

export const useDeleteCategory = (options: UseDeleteCategoryOptions = {}) => {
  const { config } = options;
  const client = useQueryClient();
  const { getParam } = useQueryParams<IFilter>({
    defaultInit: { page: '0' },
  });
  const params = useParams();

  const mutationFn = async (id: string) => {
    try {
      const res = await deleteCategory(id);

      // update provinces in query client (cache data)
      client.invalidateQueries({
        predicate: (query: any) =>
          query.queryKey[0] === 'categories' &&
          query.queryKey[1] &&
          query.queryKey[1]?.page == getParam('page'),
      });

      // update cash for category detailes for matched id [it works only when delete sub category in category detailes page]
      if (params?.id) {
        client.setQueryData(
          ['categories', { id: params.id }],
          (prev: ICategoryDetailes = {} as ICategoryDetailes) => {
            const result = deleteOne(prev.sub_category, (c) => c.id == id);
            return {
              ...prev,
              sub_category: result,
            };
          },
        );
      }

      notify.success(<Translator textKey="deleteSuccess" />);
      return res;
    } catch (error: any) {
      notify.error(error?.response?.data?.errors[0]);
    }
  };

  return useMutation({
    mutationFn: mutationFn as any,
    mutationKey: ['categories'],
    ...config,
  });
};
