import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UseMutationOptions } from 'src/lib/react-query';
import { notify } from 'src/lib/notify';
import { addRole } from './addRole';
import { RolesResponse, Role, RoleSchema } from '../../types';
import { Translator } from 'src/lib/localization';

interface UseAddRoleOptions {
  config?: UseMutationOptions<Role, RoleSchema>;
}

export const useAddRole = (options: UseAddRoleOptions = {}) => {
  const { config } = options;
  const client = useQueryClient();

  const mutationFn = async (values: RoleSchema) => {
    const res = await addRole(values);

    // update roles in query client (cache data)
    client.setQueryData(
      ['roles', { page: '0', search: '', skip: '0', take: '25' }],
      (prev: RolesResponse = { total: 0, data: [] }) => ({
        total: ++prev.total,
        data: [res, ...prev.data],
      }),
    );

    notify.success(<Translator textKey="addSuccess" />);
    return res;
  };

  return useMutation({
    mutationFn,
    mutationKey: ['roles', { page: '0', search: '', skip: '0', take: '25' }],
    ...config,
  });
};
