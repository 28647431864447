import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { DialogProps } from '@mui/material/Dialog';
import { FC } from 'src/types';
import { Button } from '../Button';
import { ILangKey, Translator } from 'src/lib/localization';

export type AlertDialogProps = DialogProps & {
  action: () => void | Promise<void>;
  handleClose: () => void;
  title?: ILangKey;
  message?: ILangKey;
  okTitle?: ILangKey;
  notOkTitle?: ILangKey;
};

export const AlertDialog: FC<AlertDialogProps> = ({
  open,
  handleClose,
  action,
  title = 'deleteData',
  message = 'deleteAlert',
  okTitle = 'yes',
  notOkTitle = 'cancel',
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Translator textKey={title} />
      </DialogTitle>
      <DialogContent sx={{ minWidth: '280px', width: '70vw', maxWidth: '550px' }}>
        <DialogContentText id="alert-dialog-description">
          <Translator textKey={message} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          <Translator textKey={notOkTitle} />
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            action();
            handleClose();
          }}
          autoFocus
        >
          <Translator textKey={okTitle} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
