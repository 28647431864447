import { privateApi } from 'src/lib/api';
import { toStringUrlParams } from 'src/utils';
import { CityResponse } from 'src/features/province/types';

export async function searchCities(search: string): Promise<CityResponse> {
  const api = privateApi();
  const urlParams = toStringUrlParams({ search });

  const response = await api.get<CityResponse>(`/employee/city?${urlParams}`);
  const { data, total } = response.data;
  return { data, total };
}
