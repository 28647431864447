import { useMutation } from '@tanstack/react-query';
import { UseMutationOptions } from 'src/lib/react-query';
import { notify } from 'src/lib/notify';
import { editRole } from './editRole';
import { Translator } from 'src/lib/localization';
import { RoleSchema } from '../../types';

interface UseEditRoleOptions {
  config?: UseMutationOptions<
    void,
    {
      roleId: string;
      values: RoleSchema;
    }
  >;
}

export const useEditRole = (options: UseEditRoleOptions = {}) => {
  const { config } = options;

  const mutationFn = async ({ roleId, values }: { roleId: string; values: RoleSchema }) => {
    const res = await editRole(roleId, values);

    notify.success(<Translator textKey="editSuccess" />);
    return res;
  };

  return useMutation({
    mutationFn,
    mutationKey: ['roles-update'],
    ...config,
  });
};
