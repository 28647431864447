import { useQuery } from '@tanstack/react-query';
import { IUser } from '../../types';
import { getInfo } from './getInfo';
import { UseQueryOptions } from 'src/lib/react-query';

interface UseGetInfoOptions {
  config?: UseQueryOptions<Omit<IUser, 'token'>>;
}

export const useGetInfo = (options: UseGetInfoOptions = {}) => {
  const { config } = options;

  return useQuery({
    queryKey: ['user'],
    queryFn: getInfo,
    ...config,
  });
};
