import MuiIconButton, { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton';
import { FC } from 'src/types';
import { Tooltip } from 'src/components';
import { ILangKey, useLocalization } from 'src/lib/localization';
import { CircularProgress } from '@mui/material';
import { cx } from 'src/utils';

export type IconButtonProps = MuiIconButtonProps & {
  toolTipTitle: ILangKey;
  loading?: boolean;
  buttonClasses?: string;
  containerClasses?: string;
};

export const IconButton: FC<IconButtonProps> = ({
  toolTipTitle,
  children,
  loading = false,
  className = '',
  buttonClasses: buttonClass = '',
  containerClasses: containerClass = '',
  ...props
}) => {
  const { translator } = useLocalization();
  return (
    <Tooltip title={translator(toolTipTitle)} className={cx(className, containerClass)}>
      <MuiIconButton {...props} className={cx(buttonClass)}>
        {loading ? <CircularProgress size=".9em" /> : children}
      </MuiIconButton>
    </Tooltip>
  );
};
