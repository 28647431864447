import { Response, IFilter } from 'src/types';
import { EmployeeResponse, IEmployee } from '../../types';
import { privateApi } from 'src/lib/api';
import { toStringUrlParams } from 'src/utils';

export async function getEmployees(filters: IFilter): Promise<EmployeeResponse> {
  const api = privateApi();
  const urlParams = toStringUrlParams(filters);
  const response = await api.get<Response<IEmployee>>(`/employee/user?${urlParams}`);
  const { data, total } = response.data;
  return { data, total };
}
