import { Routes, Route } from 'react-router-dom';
import { lazyImport } from 'src/utils';
const { Employees } = lazyImport(() => import('../pages'), 'Employees');

export const EmployeesRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Employees />} />
    </Routes>
  );
};
