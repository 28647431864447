import { Box } from 'src/components';
import { FC } from 'src/types';
import styles from './styles.module.scss';
import { cx } from 'src/utils';

type ContainerProps = {
  children: React.ReactNode;
};

export const Container: FC<ContainerProps> = ({ children }) => {
  return <Box className={cx(styles.container)}>{children}</Box>;
};
