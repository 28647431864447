import { Routes, Route } from 'react-router-dom';
import { lazyImport } from 'src/utils';
const { Profile } = lazyImport(() => import('./pages/Profile'), 'Profile');

export const ProfileRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Profile />} />
    </Routes>
  );
};
