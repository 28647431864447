import { UseQueryOptions } from 'src/lib/react-query';
import { getEmployees } from './getEmployees';
import { EmployeeResponse } from '../../types';
import { usePagination } from 'src/hooks';

interface useGetAdminsOptions {
  config?: UseQueryOptions<EmployeeResponse>;
}

export const useGetEmployees = (options: useGetAdminsOptions = {}) => {
  const { config } = options;

  return usePagination<EmployeeResponse>({
    queryKey: 'employees',
    apiFn: getEmployees,
    ...config,
  });
};
