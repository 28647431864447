import React, { useRef } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import { FC } from 'src/types';
type FileInputButtonProps = {
  handleFileChange: (e: any) => void;
  sx: any;
};

export const FileInputButton: FC<FileInputButtonProps> = ({ sx, handleFileChange }) => {
  const inputRef = useRef<any>(null);

  const handleChange = (e: any) => {
    handleFileChange(e);
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    inputRef.current.click();
  };

  return (
    <Box
      sx={{
        display: 'inline-block',
        width: 'fit-content',
        borderRadius: '50%',
        ...sx,
      }}
    >
      <input type="file" ref={inputRef} onChange={handleChange} style={{ display: 'none' }} />
      <button
        onClick={handleButtonClick}
        style={{
          background: 'var(--primary-color)',
          width: '4em',
          height: '4em',
          borderRadius: '50%',
          outline: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '4px solid #fff',
        }}
      >
        <AddIcon
          sx={{
            color: '#fff',
            width: '2rem',
            height: '2rem',
          }}
        />
      </button>
    </Box>
  );
};
