import React, { useEffect, useState } from 'react';
import { DataGrid as MuiDataGrid, DataGridProps as MuiDataGridProps } from '@mui/x-data-grid';
import { FC, IFilter } from 'src/types';
import { LinearProgress, Box, Text } from '../';
import { useQueryParams } from 'src/hooks';
import './styles.scss';
import { cx } from 'src/utils';

type DataGridProps = MuiDataGridProps & {
  paginationable?: boolean;
};

export const DataGrid: FC<DataGridProps> = React.memo(
  ({ rows, columns, loading, rowCount, paginationable = true, ...props }) => {
    const { setParams } = useQueryParams<IFilter>();

    const [rowCountState, setRowCountState] = useState(rows.length || 0);
    const [paginationModel, setPaginationModel] = useState({
      page: 0,
      pageSize: 25,
    });

    useEffect(() => {
      setRowCountState((prevRowCountState) =>
        rowCount !== undefined ? rowCount : prevRowCountState,
      );
    }, [rowCount, setRowCountState]);

    return (
      <Box className={cx('data-grid-wrapper')}>
        <>
          <MuiDataGrid
            disableColumnSelector
            rowHeight={55}
            rows={rows}
            columns={columns}
            rowCount={rowCountState}
            loading={loading}
            hideFooter={paginationable ? false : true}
            initialState={
              paginationable
                ? {
                    pagination: { paginationModel },
                  }
                : undefined
            }
            paginationMode={paginationable ? 'server' : undefined}
            pageSizeOptions={paginationable ? [25] : undefined}
            paginationModel={paginationable ? paginationModel : undefined}
            onPaginationModelChange={
              paginationable
                ? (p) => {
                    setPaginationModel(p);
                    setParams({
                      page: p.page.toString(),
                      take: p.pageSize.toString(),
                      skip: (p.page * p.pageSize).toString(),
                    });
                  }
                : undefined
            }
            slots={{
              loadingOverlay: LinearProgress,
              noRowsOverlay: () => (
                <Text
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  height={'100%'}
                >
                  No Data
                </Text>
              ),
            }}
            {...props}
          />
        </>
      </Box>
    );
  },
);
