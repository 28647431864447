import { useQuery } from '@tanstack/react-query';
import { getCategoryById } from './getCategoryById';
import { UseQueryOptions } from 'src/lib/react-query';
import { ICategoryDetailes } from '../../types';

interface UseGetCategoryByIdOptions {
  categoryId: string;
  config?: UseQueryOptions<ICategoryDetailes>;
}

export const useGetCategoryById = (options: UseGetCategoryByIdOptions) => {
  const { categoryId, config } = options;

  return useQuery({
    queryKey: ['categories', { id: categoryId }],
    queryFn: () => getCategoryById(categoryId),
    ...config,
  });
};
