import { InputAdornment, useTheme } from '@mui/material';
import { FC } from 'react';
import { Controller, useFormState } from 'react-hook-form';
import { IconType } from 'react-icons';
import { TextField as T, TextFieldProps as TProps } from 'src/components';
import { ErrorMessage } from 'src/components/ErrorMessage';
import { ILangKey, useLocalization } from 'src/lib/localization';

export type TextFieldProps = Omit<TProps, 'name' | 'label' | 'placeholder'> & {
  name: string;
  control: any;
  label?: ILangKey;
  StartIcon?: IconType;
  EndIcon?: IconType;
  placeholder?: ILangKey;
};

export const TextField: FC<TextFieldProps> = ({
  name,
  control,
  sx,
  StartIcon,
  EndIcon,
  label,
  placeholder,
  ...props
}) => {
  const { errors } = useFormState({ control, name: name });
  const error = errors[name]?.message;
  const theme = useTheme();
  const { translator } = useLocalization();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <T
          InputProps={{
            startAdornment: StartIcon && (
              <InputAdornment position="start">
                <StartIcon style={{ color: theme.palette.primary.main, fontSize: '1.3rem' }} />
              </InputAdornment>
            ),
            endAdornment: EndIcon && (
              <InputAdornment position="end">
                <EndIcon style={{ color: theme.palette.primary.main, fontSize: '1.3rem' }} />
              </InputAdornment>
            ),
          }}
          size="small"
          {...props}
          {...field}
          error={error ? true : false}
          helperText={<ErrorMessage error={error as any} />}
          sx={{ width: '100%', ...(sx || {}) }}
          label={label ? translator(label) : undefined}
          placeholder={placeholder ? translator(placeholder) : undefined}
        />
      )}
    />
  );
};
