import { Route, Routes } from 'react-router-dom';
import { lazyImport } from 'src/utils';
const { Province } = lazyImport(() => import('../pages'), 'Province');
const { ProvinceDetail } = lazyImport(() => import('../pages'), 'ProvinceDetail');

export const ProvinceRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Province />} />
      <Route path="/:id" element={<ProvinceDetail />} />
    </Routes>
  );
};
