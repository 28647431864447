import { useMutation } from '@tanstack/react-query';
import { UseMutationOptions } from 'src/lib/react-query';
import { notify } from 'src/lib/notify';
import { editCategory } from './editCategory';
import { ICategory, ICategorySchema } from '../../types';
import { useLocalization } from 'src/lib/localization';

interface UseEditCategoryOptions {
  config?: UseMutationOptions<
    ICategory,
    {
      categoryId: string;
      category: ICategorySchema;
    }
  >;
}

export const useEditCategory = (options: UseEditCategoryOptions = {}) => {
  const { config } = options;
  const { translator } = useLocalization();

  const mutationFn = async ({
    categoryId,
    category,
  }: {
    categoryId: string;
    category: ICategorySchema;
  }) => {
    const res = await editCategory(categoryId, category);

    notify.success(translator('editSuccess'));
    return res;
  };

  return useMutation({
    mutationFn: mutationFn as any,
    mutationKey: ['categories'],
    ...config,
  });
};
