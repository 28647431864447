export const lang = {
  en: {
    // * general
    open: 'Open',
    close: 'Close',
    edit: 'Edit',
    delete: 'Delete',
    save: 'Save',
    add: 'Add',
    addSuccess: 'Added Successfully',
    editSuccess: 'Edited Successfully',
    deleteSuccess: 'Deleted Successfully',
    deleteAlert: 'Are you sure you want to delete this data?',
    yes: 'Yes',
    cancel: 'Cancel',
    deleteData: 'Delete Data',
    search: 'Search',
    name: 'Name',
    phone: 'Phone',
    created_at: 'Created at',
    icon: 'Icon',
    title: 'Title',
    type: 'Type',
    filters: 'Filters',
    reset: 'Reset',
    first_name: 'First Name',
    last_name: 'Last Name',
    address: 'Address',
    users: 'Users',
    sendTo: 'Send to',
    et: 'Elapsed Time',
    na: 'N/A',
    totalPrice: 'Total price',
    note: 'Note',
    copy: 'Copy',
    uploadImage: 'Upload Image',
    amount: 'Amount',
    logOut: 'Log out',
    day: 'Day',
    hour: 'Hour',
    pageNotFound: 'Page Not Found',
    goBack: 'Go Back',
    tryAgain: 'Try Again',

    // * auth feature
    signin: 'Sign in',
    welcomeBack: 'Welcome back',
    showPassword: 'Show password',
    hidePassword: 'Hide password',
    email: 'Email Address',
    password: 'Password',
    signInScuccess: 'Signed in successfully',

    // * main layout
    home: 'Home',
    provinces: 'Provinces',
    english: 'English',
    arabic: 'Arabic',

    // * province
    name_en: 'name in english',
    name_ar: 'name in arabic',
    actions: 'Actions',
    provinceDetail: 'Province Detail',
    province: 'Province',
    city: 'City',
    cities: 'Cities',
    addProvince: 'Add Province',
    editProvince: 'Edit Province',
    addCity: 'Add City',
    editCity: 'Edit City',

    // * categories
    categories: 'Categories',
    searchCategory: 'Search for a category',
    belongsToCategory: 'Belongs to Category',
    addCategory: 'Add Category',
    categoryDetailes: 'Category Details',
    subCategories: 'Sub Categories',

    // * admins
    admins: 'Admins',
    status: 'Status',
    addAdmin: 'Add Admin',
    editAdmin: 'Edit Admin',
    active: 'Active',
    inactive: 'Inactive',

    // * employees
    employees: 'Employees',
    addEmployee: 'Add an Employee',
    editEmployee: 'Edit Employee',
    searchEmployee: 'Search for an employee',
    deactivateEmployee: 'Deactivate employee',
    deactivateAlertEmployee: 'Are you sure you want to deactivate this employee?',
    activateEmployee: 'Activate Employee',
    activateAlertEmployee: 'Are you sure you want to activate this employee?',
    deactivateSuccess: 'Deactivated Successfully',
    activateSuccess: 'Activated Successfully',
    activate: 'Activate',
    uploadProfilePic: 'Upload Profile Picture',

    // * banner
    banners: 'Banners',
    banner: 'Banner',
    description: 'Description',
    url: 'URL',
    deleteBanner: 'Delete Banner',
    addBanner: 'Add Banner',
    editBanner: 'Edit Banner',
    uploadBanner: 'Upload Banner',

    // * items
    items: 'Items',
    price: 'Price',
    category: 'Category',
    item: 'Item',
    package: 'Package',
    isActive: 'Is Active',
    isFeatured: 'Is Featured',
    priceNegotiable: 'Price Negotiable',
    addItem: 'Add Item',
    itemName: 'Item Name',
    oldPrice: 'Old Price',
    averageTime: 'Average Time',
    isPackage: 'Is Package',
    itemDetailes: 'Item Details',

    // * validation
    validation_email: 'Invalid email',
    validation_min: 'Must be at least {{min}} characters',
    validation_required: 'This field is required',
    validation_moreThan: 'Must be more than {{value}}',
    validation_array_min: 'Must add at least one item',
    validations_invalidType: 'invalid type, type must be {{type}}',
    validation_stringOneOf: 'Passwords do not match',

    // * orders
    orders: 'Orders',
    orderNumber: 'Order Number',
    customer: 'Customer',
    clickToShowCustomer: 'Show customer information',
    orderEmployee: 'Assigned Employee',
    customerAvailableAt: 'Customer Available at',
    addOrder: 'Add Order',
    customerAddress: 'Customer Address',
    orderItems: 'Order Items',
    orderInfo: 'Order Info',
    customerInfo: 'Customer Info',
    discount: 'Discount',
    discountType: 'Discount Type',
    percentage: 'Percentage',
    fixed: 'Fixed',
    total: 'Total',
    totalWithoutDiscount: 'Total without Discount',
    orderLogs: 'Order Logs',
    isPrivate: 'Private',

    // * notifications
    notifications: 'Notifications',
    title_en: 'Title in english',
    title_ar: 'Title in arabic',
    body_en: 'Notification body in english',
    body_ar: 'Notification body in arabic',
    sendNotification: 'Send  notification',
    deleteNotification: 'Delete notification',
    all: 'All',
    tocustomers: 'Customers',
    guests: 'Guests',
    deactivate: 'Deactivate',

    // * expenses
    expenses: 'Expenses',
    addExpense: 'Add an Expense',
    editExpense: 'Edit Expense Information',
    deleteExpense: 'Delete Expense',
    uploadFile: 'Upload File',

    // * roles
    permissions: 'permissions',
    roles: 'Roles',
    role: 'Role',
    permission: 'permission',
    addRole: 'Add a Role',
    editRole: 'Edit Role Information',
    deleteRole: 'Delete Role',

    // * customers
    customers: 'customers',
    searchCustomer: 'Search a customer by name or email address',
    deactivateCustomer: 'Deactivate Customer',
    deactivateAlertCustomer: 'Are you sure you want to deactivate this customer?',
    activateCustomer: 'Activate Customer',
    activateAlertCustomer: 'Are you sure you want to activate this customer?',

    // * feedbacks
    feedbacks: 'Feedbacks',
    comment: 'Message',
    rating: 'Rating',
    deleteFeedback: 'Delete Feedback',

    // * reset password
    resetPassword: 'Reset Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm New Passowrd',
    passwordMatch: 'Passwords must match',
    enterNewPassword: 'Please enter and confirm your new password.',

    // * verify email
    congratulations: 'Congratulations!',
    verifyEmailSuccess: 'Your email has been successfully verified.',
    error: 'Error!',
  },

  ar: {
    // * general
    open: 'أضهار',
    close: 'أغلاق',
    edit: 'تعديل',
    delete: 'حذف',
    save: 'حفظ',
    add: 'أضافة',
    addSuccess: 'تمت الاضافة بنجاح',
    editSuccess: 'تم تحديث البيانات بنجاح',
    deleteSuccess: 'تم الحذف بنجاح',
    deleteAlert: 'هل انت متأكد من حذف هذه البيانات؟',
    yes: 'نعم',
    cancel: 'الغاء',
    deleteData: 'حذف بيانات',
    search: 'بحث',
    name: 'الاسم',
    phone: 'رقم الهاتف',
    created_at: 'تاريخ الانشاء',
    icon: 'ألايقونة',
    title: 'العنوان',
    type: 'النوع',
    filters: 'تصفية',
    reset: 'الغاء التصفية',
    first_name: 'الاسم الاول',
    last_name: 'اسم العائلة',
    address: 'العنوان',
    users: 'المستخدمين',
    et: 'الوقت المنقضي',
    na: 'غير متوفر',
    totalPrice: 'السعر الكلي',
    note: 'ملاحظة',
    copy: 'نسخ',
    uploadImage: 'تحميل صورة',
    amount: 'الكمية',
    logOut: 'تسجيل الخروج',
    day: 'يوم',
    hour: 'ساعة',
    pageNotFound: 'الصفحة غير موجودة',
    goBack: 'الرجوع',
    tryAgain: 'اعادة المحاولة',

    // * auth feature
    signin: 'تسجيل الدخول',
    welcomeBack: 'أهلاّّ بعودتك',
    showPassword: 'أضهار الرمز',
    hidePassword: 'اخفاء الرمز',
    email: 'البريد ألالكتروني',
    password: 'كلمة المرور',
    signInScuccess: 'تم تسجيل الدخول بنجاح',

    // * main layout
    home: 'الصفحة الرئيسية',
    provinces: 'المحافظات',
    english: 'ألانجليزية',
    arabic: 'ألعربية',

    // * province
    name_en: 'ألاسم بالانجليزية',
    name_ar: 'الاسم بالعربية',
    actions: 'العمليات',
    provinceDetail: 'معلومات المحافضة',
    cities: 'المدن',
    province: 'المحافظة',
    city: 'المدينة',
    addProvince: 'اضافة محافضة',
    editProvince: 'تعديل معلومات المحافضة',
    addCity: 'اضافة مدينة',
    editCity: 'تعديل معلومات المدينة',

    // * categories
    categories: 'الاصناف',
    searchCategory: 'البحث عن صنف',
    belongsToCategory: 'تابع لصنف',
    addCategory: 'أضافة صنف',
    categoryDetailes: 'معلومات الصنف',
    subCategories: 'الفئات الفرعية',

    // * admins
    admins: 'الموظفين',
    status: 'الحالة',
    addAdmin: 'أضافة مومظف',
    editAdmin: 'تعديل معلومات الموظف',
    active: 'مفعل',
    inactive: 'معطل',

    // * employees
    employees: 'الموظفين',
    addEmployee: 'اضافة موظف',
    editEmployee: 'تعديل معلومات الموظف',
    searchEmployee: 'البحث عن موظف',
    deactivateEmployee: 'تعطيل الموظف',
    deactivateAlertEmployee: 'هل انت متأكد من تعطيل هذا الموظف؟',
    activateEmployee: 'تفعيل الموظف',
    activateAlertEmployee: 'هل انت متأكد من تفعيل هذا الموظف',
    deactivateSuccess: 'تم التعطيل بنجاح',
    activateSuccess: 'تم التفعيل بنجاح',
    deactivate: 'تعطيل',
    activate: 'تفعيل',
    uploadProfilePic: 'تحميل صورة شخصية',

    // * banner
    banners: 'الشعارات',
    banner: 'شعار',
    description: 'الوصف',
    url: 'الرابط',
    deleteBanner: 'مسح الشعار',
    addBanner: 'اضافة شعار',
    editBanner: 'تعديل الشعار',

    uploadBanner: 'تحميل شعار',

    // * items
    items: 'المواد',
    price: 'السعر',
    category: 'الصنف',
    item: 'مادة',
    package: 'حزمة',
    isActive: 'مفعل',
    isFeatured: 'مميز',
    priceNegotiable: 'السعر قابل للتفاوض',
    addItem: 'أضافة مادة',
    itemName: 'أسم المادة',
    oldPrice: 'السعر القديم',
    averageTime: 'متوسط الوقت',
    isPackage: 'هل هو حزمة؟',
    itemDetailes: 'معلومات المادة',

    // * validation
    validation_email: 'البريد الالكتروني غير صالح',
    validation_min: 'يجب ان يكون على الاقل {{min}} رموز',
    validation_required: 'هذا الحقل مطلوب',
    validation_moreThan: 'يجب ان يكون اكثر من {{value}}',
    validation_array_min: 'يجب أضافة عنصر واحد كحد ادنى',
    validations_invalidType: 'نوع البيانات غير صالح, يحب ادخال بيانات من نوع {{type}}',
    validation_stringOneOf: 'كلمة المرور غير متطابقة',

    // * orders
    orders: 'الطلبات',
    orderNumber: 'رقم الطلب',
    customer: 'العميل',
    clickToShowCustomer: 'عرض معلومات العميل',
    orderEmployee: 'الموظفون',
    customerAvailableAt: 'تاريخ تواجد العميل',
    addOrder: 'اضافة طلب',
    customerAddress: 'عنوان العميل',
    orderItems: 'مواد الطلب',
    orderInfo: 'معلومات الطلب',
    customerInfo: 'معلومات العميل',
    discount: 'الخصم',
    discountType: 'نوع الخصم',
    percentage: 'نسبة',
    fixed: 'قطعي',
    total: 'الاجمالي',
    totalWithoutDiscount: 'الاجمالي بدون الخصم',
    orderLogs: 'التعليقات',
    isPrivate: 'خاص',

    // * notifications
    notifications: 'الاشعارات',
    title_en: 'عنوان الاشعار بالانجليزية',
    title_ar: 'عنوان الاشعار بالعربية',
    body_en: 'نص الاشعار بالانجليزية',
    body_ar: 'نص الاشعار بالعربية ',
    sendNotification: 'ارسال اشعار',
    deleteNotification: 'مسح الاشعار',
    all: 'الكل',
    tocustomers: 'الزبائن فقط',
    guests: 'الضيوف فقط',
    sendTo: 'ارسل الى',

    // * expenses
    expenses: 'المصاريف',
    addExpense: 'اضافة مصاريف',
    editExpense: 'تعديل معلومات المصروف ',
    deleteExpense: 'مسح المصروف',
    uploadFile: 'تحميل ملف',

    // * roles
    permissions: 'الصلاحيات',
    roles: 'الوظائف',
    role: 'وظيفة',
    permission: 'اذن',
    addRole: 'اضافة وظيفة',
    editRole: 'تعديل معلومات الوظيفة',
    deleteRole: 'مسح الوظيفة',

    // * customers
    customers: 'العملاء',
    searchCustomer: 'ابحث عن اسم او البريد الالكتروني الخاص بالعميل',
    deactivateCustomer: 'تعطيل العميل',
    activateCustomer: 'تفعيل العميل',
    deactivateAlertCustomer: 'هل انت متأكد من تعطيل هذا العميل',
    activateAlertCustomer: 'هل انت متأكد من تفعيل هذا العميل',

    // * feedbacks
    feedbacks: 'المراجعات',
    comment: 'التعليق',
    rating: 'التقييم',
    deleteFeedback: 'مسح المراجعة',

    // * reset password
    resetPassword: 'اعادة تعيين كلمة المرور',
    newPassword: 'كلمة المرور الجديدة',
    confirmPassword: 'تأكيد كلمة المرور الجديدة',
    passwordMatch: 'كلمة المرور غير متشابهة',
    enterNewPassword: 'الرجاء قم بادخال وتأكيد كلمة المرور الجديدة',

    // * verify email
    congratulations: '!تهانينا',
    verifyEmailSuccess: 'لقد تم التحقق من بريدك الالكتروني بنجاح.',
    error: 'خطأ!',
  },
};

export type ILang = typeof lang.en;
export type ILangKey = keyof typeof lang.en;
