import { Response, IFilter } from 'src/types';
import { RolesResponse, Role } from '../../types';
import { privateApi } from 'src/lib/api';
import { toStringUrlParams } from 'src/utils';

export async function getRoles(filters: IFilter): Promise<RolesResponse> {
  const api = privateApi();
  const urlParams = toStringUrlParams(filters);
  const response = await api.get<Response<Role>>(`/employee/roles?${urlParams}`);
  const { data, total } = response.data;
  return { data, total };
}
