/* eslint-disable react-hooks/exhaustive-deps */
import { URLSearchParamsInit, useSearchParams } from 'src/lib/router';
import { useDebouncedCallback } from '.';

const shouldDeleteValues = [null, undefined, ''];

type UseSearchParamsOptions<T = any> = {
  defaultInit?: URLSearchParamsInit | T;
};

export function useQueryParams<T extends Record<string, any>>({
  defaultInit,
}: UseSearchParamsOptions<T> = {}) {
  const [urlParams, setUrlParams] = useSearchParams(defaultInit as any);
  /**
   * Set param to search parameters.
   * @param {string} name key name for param.
   * @param {string} value value for param.
   * @return void
   */
  const setParam = useDebouncedCallback((name: keyof T, value: string) => {
    if (shouldDeleteValues.includes(value)) {
      urlParams.delete(name as string);
    } else urlParams.set(name as string, value);

    setUrlParams(urlParams, { replace: true });
  }, 700);

  /**
   * Set params to search parameters.
   * @param {object} params object that has key, value pairs for params and its values.
   */
  // const setParamsObj = (params: URLSearchParamsInit | T) => {
  //   setUrlParams(params as any, { replace: true });
  // };
  const setParamsObj = useDebouncedCallback((params: T) => {
    const keys = (Object.keys(params) as Array<keyof T>) || [];
    keys.forEach((k) => {
      if (shouldDeleteValues.includes(params[k])) {
        urlParams.delete(k as string);
      } else urlParams.set(k as string, params[k]);
    });
    setUrlParams(urlParams, { replace: true });
  }, 350);

  /**
   * Get value for spicific param by pass its name.
   * @param {string} name name for param that want to retrieve its value.
   * @returns
   */
  const getParam = (name: keyof T) => {
    return urlParams.get(name as string);
  };

  /**
   * delete all search params.
   */
  const clear = () => {
    setUrlParams({}, { replace: true });
  };

  return {
    params: urlParams,
    setParam,
    getParam,
    setParams: setParamsObj,
    clear,
  };
}
