import { Routes, Route } from 'react-router-dom';
import { lazyImport } from 'src/utils';
const { Expenses } = lazyImport(() => import('./pages/Expenses'), 'Expenses');

export const ExpensesRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Expenses />} />
    </Routes>
  );
};
