import { useQuery } from '@tanstack/react-query';
import { searchCategories } from './searchCategories';
import { UseQueryOptions } from 'src/lib/react-query';
import { IGetCategoriesResponse } from '../../types';

interface UseSearchCategoriesOptions {
  search: string;
  config?: UseQueryOptions<IGetCategoriesResponse>;
}

export const useSearchCategories = (options: UseSearchCategoriesOptions) => {
  const { search, config } = options;

  return useQuery({
    queryKey: ['search-categories', search],
    queryFn: () => searchCategories(search),
    keepPreviousData: true,
    ...config,
  });
};
