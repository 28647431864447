import React from 'react';
import { FC } from 'src/types';
import { Loading } from '../Loading';

export type HandleLoadingProps = {
  isLoading: boolean;
  children: React.ReactNode;
};

// TODO: Change its name to handle status and make it handle error status with loading status
/**
 * return loading feedback when isLoading true, otherwise returns passed chiledren
 */
export const HandleLoading: FC<HandleLoadingProps> = ({ isLoading, children }) => {
  if (isLoading) {
    return <Loading />;
  }

  return <>{children}</>;
};
