import MuiMenu,{MenuProps as MuiMenuProps} from '@mui/material/Menu';
import { FC } from 'src/types';

type MenuProps = MuiMenuProps;

export const Menu:FC<MenuProps> = (props) => {
  return (
    <MuiMenu {...props}/>
  )
}
