import { RoleSchema } from '../../types';
import { RolesResponse, Role } from '../../types';
import { privateApi } from 'src/lib/api';

export async function addRole(values: RoleSchema): Promise<Role> {
  const api = privateApi();
  const response = await api.post<RolesResponse>(`/employee/roles`, values);
  const { data } = response.data;
  return data[0];
}
