import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { ILangCode, ILocalizationContext } from './types';
import { FC } from 'src/types';
import { lang, ILang, ILangKey } from './lang';
import { getBrowserLanguage } from './utils';
import { useAuth } from '../auth';
import { useUpdateUser } from 'src/features/auth/api/updateUser';
import { useQueryClient } from '@tanstack/react-query';
import { IUser } from 'src/features/auth';

const LocalizationContext = createContext<ILocalizationContext>({
  code: 'en',
  direction: 'ltr',
  lang: {} as any,
  translator: (key) => '',
  changeLanguage: (code) => {},
  changeCode: (code) => {},
});

export type LocalizationProviderProps = {
  langCode?: ILangCode;
  children: React.ReactNode;
};

export const LocalizationProvider: FC<LocalizationProviderProps> = ({
  langCode = (localStorage.getItem('code') as ILangCode) || getBrowserLanguage(),
  children,
}) => {
  const { user } = useAuth();
  const client = useQueryClient();
  const [code, setCode] = useState((user?.locale as ILangCode) || langCode);
  const [language, setLanguge] = useState<ILang>(lang[langCode]);
  const direction = code === 'en' ? 'ltr' : 'rtl';
  const { mutateAsync: updateUser, isSuccess } = useUpdateUser();

  const translator = useCallback(
    (key: ILangKey, values?: any) => {
      if (values) {
        const keyValue: any = Object.keys(values)[0];
        const regex = /{{.*?}}/gi;
        return language[key].replace(regex, values[keyValue]);
      }
      return language[key];
    },
    [language],
  );

  const changeLanguage = async (code: ILangCode) => {
    localStorage.setItem('code', code);
    console.log(user);
    if (user) {
      let data = user;
      data.locale = code;
      delete data.profile_pic;
      await updateUser(data);
    } else {
      // Fetch user data from cache if not available
      const cachedUserData = client.getQueryData<IUser>(['user']);
      if (cachedUserData) {
        let data = cachedUserData;
        data.locale = code;
        delete data.profile_pic;
        await updateUser(data);
      }
    }

    isSuccess && setCode(code);
  };

  const changeCode = (code: ILangCode) => {
    setCode(code);
  };

  useEffect(() => {
    setLanguge(lang[code]);

    document.querySelector('html')?.setAttribute('lang', code);
    document.querySelector('html')?.setAttribute('dir', direction);
  }, [code]);

  return (
    <LocalizationContext.Provider
      value={{ code, direction, lang: language, changeLanguage, translator, changeCode }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocalization = () => {
  const context = useContext(LocalizationContext);

  if (!context) {
    throw new Error('useLocalization hook must be used inside LocalizationProvider.');
  }

  return context;
};
