import { IGetCategoryResponse, ICategoryDetailes } from '../../types';
import { privateApi } from 'src/lib/api';

export async function getCategoryById(categoryId: string): Promise<ICategoryDetailes> {
  const api = privateApi();

  const response = await api.get<IGetCategoryResponse>(`/employee/category/${categoryId}`);
  const { data } = response.data;
  return data[0];
}
