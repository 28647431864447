import { ChipTypeMap } from '@mui/material';
import MuiAutocomplete, {
  AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material/Autocomplete';
import { InputAdornment, useTheme } from '@mui/material';
import { Controller, FieldError, FieldErrorsImpl, Merge, useFormState } from 'react-hook-form';
import { IconType } from 'react-icons';
import { ErrorMessage } from 'src/components/ErrorMessage';
import { ILangKey, useLocalization } from 'src/lib/localization';
import { TextField } from '../../TextField';

export type AutocompleteProps<
  Value,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
> = Omit<
  MuiAutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>,
  'name' | 'label' | 'renderInput'
> & {
  name: string;
  control: any;
  label?: ILangKey;
  placeholder?: ILangKey;
  StartIcon?: IconType;
  EndIcon?: IconType;
  error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
};

// TODO: refactor
export function Autocomplete<
  Value,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>({
  name,
  control,
  label,
  placeholder,
  StartIcon,
  EndIcon,
  defaultValue,
  error: comingError,
  ...props
}: AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>) {
  const { errors } = useFormState({ control, name: name });
  const error = errors[name]?.message || comingError;
  const { translator } = useLocalization();
  const theme = useTheme();

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ...restField } }) => (
          <MuiAutocomplete
            {...props}
            {...restField}
            autoComplete
            includeInputInList
            filterSelectedOptions
            sx={{ width: '100%' }}
            autoHighlight
            onChange={(event: any, newValue) => {
              onChange(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  startAdornment: StartIcon && (
                    <InputAdornment position="start">
                      <StartIcon
                        style={{ color: theme.palette.primary.main, fontSize: '1.3rem' }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: EndIcon && (
                    <InputAdornment position="end">
                      <EndIcon style={{ color: theme.palette.primary.main, fontSize: '1.3rem' }} />
                    </InputAdornment>
                  ),
                  autoComplete: 'new-password',
                }}
                size="small"
                error={error ? true : false}
                helperText={<ErrorMessage error={error as any} />}
                sx={{ width: '100%' }}
                label={label ? translator(label) : undefined}
                placeholder={placeholder ? translator(placeholder) : undefined}
              />
            )}
          />
        )}
      />
    </>
  );
}
