import { FormControlLabel, FormGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { Container, Checkbox, Box, Grid, Text, Title } from 'src/components';

export const PermissionsCheckboxList = ({
  permissions,
  existingPermissions,
  onPermissionsChange,
}: any) => {
  const [checkedItems, setCheckedItems] = useState<any>({});

  useEffect(() => {
    if (existingPermissions) {
      const initialCheckedItems = {} as any;
      existingPermissions.forEach((permission: any) => {
        initialCheckedItems[permission.id] = true;
      });
      setCheckedItems(initialCheckedItems);
    }
  }, [existingPermissions]);

  const handleCheckboxChange = (id: any) => (event: any) => {
    setCheckedItems({ ...checkedItems, [id]: event.target.checked });
    const updatedCheckedItems = { ...checkedItems, [id]: event.target.checked };
    setCheckedItems(updatedCheckedItems);
    // Invoke the callback with the updated permissions
    onPermissionsChange(Object.keys(updatedCheckedItems).filter((key) => updatedCheckedItems[key]));
  };

  return (
    <Box sx={{ width: '100%' }}>
      <FormGroup
        sx={{ width: '100%', display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}
      >
        <Grid container spacing={1}>
          {permissions?.map((permission: any) => (
            <Grid item xs={12} md={3} key={permission.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedItems[permission.id] || false}
                    onChange={handleCheckboxChange(permission.id)}
                    name={permission.name}
                  />
                }
                label={permission.name}
              />
            </Grid>
          ))}
        </Grid>
      </FormGroup>
    </Box>
  );
};
