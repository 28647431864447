import { UseQueryOptions } from 'src/lib/react-query';
import { getRoles } from './getRoles';
import { RolesResponse } from '../../types';
import { usePagination } from 'src/hooks';

interface useGetRolesOptions {
  config?: UseQueryOptions<RolesResponse>;
}

export const useGetRoles = (options: useGetRolesOptions = {}) => {
  const { config } = options;

  return usePagination<RolesResponse>({
    queryKey: 'roles',
    apiFn: getRoles,
    ...config,
  });
};
