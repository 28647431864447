import { useQuery } from '@tanstack/react-query';
import { getRoleById } from './getRoleById';
import { UseQueryOptions } from 'src/lib/react-query';
import { GetRoleById } from '../../types';

interface UseGetRoleByIdOptions {
  roleId: string;
  config?: UseQueryOptions<GetRoleById>;
}

export const useGetRoleById = (options: UseGetRoleByIdOptions) => {
  const { roleId, config } = options;

  return useQuery({
    queryKey: ['roles', { id: roleId }],
    queryFn: () => getRoleById(roleId),
    ...config,
  });
};
