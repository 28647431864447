import { NavLink as Link } from 'src/lib/router';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from 'src/components';
import { FC } from 'src/types';
import { cx } from 'src/utils';
import { INavItem } from './types';
import { useState } from 'react';
import { Translator, useLocalization } from 'src/lib/localization';
import styles from './styles.module.scss';

type NavItemProps = INavItem & {};

export const NavItem: FC<NavItemProps> = ({ title, link, Icon, ActiveIcon }) => {
  const [isActive, setIsActive] = useState(false);
  const { translator } = useLocalization();

  return (
    <ListItem disablePadding className={cx(styles['nav-item'])}>
      <Link
        to={link}
        className={({ isActive }) => {
          setIsActive(isActive);
          return cx(styles['nav-link'], isActive && styles['nav-link-active']);
        }}
      >
        <ListItemButton className={cx(styles['nav-item-button'])}>
          <ListItemIcon className={cx(styles['nav-icon-wrapper'])}>
            {isActive ? (
              <ActiveIcon className={cx(styles['nav-icon'])} />
            ) : (
              <Icon className={cx(styles['nav-icon'])} />
            )}
          </ListItemIcon>
          <Translator textKey={title} />
        </ListItemButton>
      </Link>
    </ListItem>
  );
};
