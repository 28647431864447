import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UseMutationOptions } from 'src/lib/react-query';
import cookies from 'js-cookie';
import { notify } from 'src/lib/notify';
import { signin } from './signin';
import { IUser, IUserSchema } from '../../types';
import { useNavigate } from 'src/lib/router';
import { useLocalization } from 'src/lib/localization';

interface UseSigninOptions {
  config?: UseMutationOptions<IUser, IUserSchema>;
}

export const useSignIn = (options: UseSigninOptions = {}) => {
  const { config } = options;
  const { translator } = useLocalization();
  const client = useQueryClient();
  const navigate = useNavigate();
  const mutationFn = async (user: IUserSchema) => {
    try {
      const res = await signin(user);
      notify.success(translator('signInScuccess'));
      client.setQueryData(['user'], res);
      cookies.set('token', res.token, { path: '/' });
      return res;
    } catch (error: any) {
      notify.error(error?.response?.data?.errors[0]);
    }
  };
  return useMutation({
    mutationFn: mutationFn as any,
    mutationKey: ['user'],
    ...config,
  });
};
