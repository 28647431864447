import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import MuiModal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { FC } from 'src/types';
import './styles.scss';
import { cx } from 'src/utils';
import { Box, IconButton, Title } from '../';
import { IoClose } from 'react-icons/io5';
import { ILangKey, Translator } from 'src/lib/localization';

type ModalProps = {
  open: boolean;
  children: React.ReactNode;
  title: ILangKey;
  handleClose: () => void;
  size?: 'sm' | 'md' | 'lg' | 'full';
};

export const Modal: FC<ModalProps> = ({ open, children, title, handleClose, size = 'md' }) => {
  return (
    <MuiModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={(event, reason) => {
        // preevent modal close when clicking outside modal
        if (reason && reason === 'backdropClick') return;
        handleClose();
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box className={cx('modal', size)}>
          <Box className={cx('modal-header')}>
            <IconButton
              toolTipTitle="close"
              onClick={handleClose}
              className={cx('modal-close-btn')}
            >
              <IoClose />
            </IconButton>
            <Title title={title} variant="h5" component="h2" />
          </Box>
          <Box className={cx('modal-content')}>{children}</Box>
        </Box>
      </Fade>
    </MuiModal>
  );
};
