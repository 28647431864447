import { useTheme } from '@mui/material';
import { MdDeleteOutline, MdOutlineEdit, MdBlock, MdPowerSettingsNew } from 'react-icons/md';
import { Grid, IconButton } from 'src/components';
import { FC } from 'src/types';
import { cx } from 'src/utils';

type IGridColumnActions = {
  children: React.ReactNode;
};

export const GridColumnActions: FC<IGridColumnActions> = ({ children }) => {
  return (
    <Grid container columnGap={'1em'}>
      {children}
    </Grid>
  );
};

type IGridAction = {
  handleClick: () => void | Promise<void>;
  loading?: boolean;
  className?: string;
};
type IDeactivateGridAction = {
  handleClick: () => void | Promise<void>;
  loading?: boolean;
  is_active?: number;
  disabled?: boolean;
};
export const DeleteAction: FC<IGridAction> = ({ handleClick, loading, className }) => {
  const theme = useTheme();

  return (
    <Grid item>
      <IconButton
        color="error"
        toolTipTitle="delete"
        onClick={handleClick}
        loading={loading}
        className={className ? className : cx('grid-delete-actions')}
      >
        <MdDeleteOutline color={theme.palette.error.main} />
      </IconButton>
    </Grid>
  );
};

export const DeactivateAction: FC<IDeactivateGridAction> = ({
  handleClick,
  loading,
  is_active,
  disabled,
}) => {
  const theme = useTheme();

  return (
    <Grid item>
      <IconButton
        color={is_active ? 'error' : 'success'}
        toolTipTitle={is_active ? 'deactivate' : 'activate'}
        onClick={handleClick}
        loading={loading}
        disabled={disabled}
        className={is_active ? cx('grid-delete-actions') : cx('grid-activate-actions')}
      >
        {is_active ? (
          <MdPowerSettingsNew color={theme.palette.error.main} />
        ) : (
          <MdPowerSettingsNew color={theme.palette.success.main} />
        )}
      </IconButton>
    </Grid>
  );
};

export const EditAction: FC<IGridAction> = ({ handleClick, loading }) => {
  const theme = useTheme();

  return (
    <Grid item>
      <IconButton
        color="primary"
        toolTipTitle="edit"
        onClick={handleClick}
        loading={loading}
        className={cx('grid-edit-actions')}
      >
        <MdOutlineEdit color={theme.palette.primary.main} />
      </IconButton>
    </Grid>
  );
};
