import { Routes, Route } from 'react-router-dom';
import { lazyImport } from 'src/utils';
const { Roles } = lazyImport(() => import('./pages/Roles'), 'Roles');

export const RolesRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Roles />} />
    </Routes>
  );
};
