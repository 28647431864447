import { Popup, Marker as MK, CircleMarker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import icon_location from './pin.png';
import { FC } from 'src/types';

const CustomIcon = new L.Icon({
  iconUrl: icon_location,
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
});

const optionLive = { color: '#2980b9' };

type LocationMarkerProps = {
  pos: [number, number];
  onMove: (pos: [number, number]) => void;
};

export const LocationMarker: FC<LocationMarkerProps> = ({ pos, onMove }) => {
  return (
    <>
      <MK
        position={pos}
        // draggable
        autoPan
        icon={CustomIcon}
        eventHandlers={{
          moveend: (event: any) => {
            onMove([event.target.getLatLng().lat, event.target.getLatLng().lng]);
          },
        }}
      />
      <CircleMarker center={pos} pathOptions={optionLive} radius={10}>
        <Popup>Popup in CircleMarker</Popup>
      </CircleMarker>
    </>
  );
};
