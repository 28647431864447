import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notify } from 'src/lib/notify';
import { logOut } from './logOut';
import { useNavigate } from 'src/lib/router';

interface UseLogoutOptions {
  config?: any;
}

export const useLogOut = (options: UseLogoutOptions = {}) => {
  const { config } = options;
  const client = useQueryClient();
  const navigate = useNavigate();
  const mutationFn = async () => {
    try {
      const res = await logOut();
      notify.success('logged out successfully');
      client.setQueryData(['user'], res);
      setTimeout(() => navigate('/auth/signin', { replace: true }), 500);
    } catch (error: any) {
      notify.error(error?.response?.data?.errors[0]);
    }
  };
  return useMutation({
    mutationFn: mutationFn,
    mutationKey: ['user'],
    ...config,
  });
};
