import { useQuery } from '@tanstack/react-query';
import { searchCities } from './searchCities';
import { UseQueryOptions } from 'src/lib/react-query';
import { CityResponse } from 'src/features/province/types';

interface UseSearchCitiesOptions {
  search: string;
  config?: UseQueryOptions<CityResponse>;
}

export const useSearchCities = (options: UseSearchCitiesOptions) => {
  const { search, config } = options;

  return useQuery({
    queryKey: ['search-cities', search],
    queryFn: () => searchCities(search),
    keepPreviousData: true,
    ...config,
  });
};
