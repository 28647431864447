import { IFilter } from 'src/types';
import { IGetCategoriesResponse } from '../../types';
import { privateApi } from 'src/lib/api';
import { toStringUrlParams } from 'src/utils';

export async function getCategories(filters: IFilter): Promise<IGetCategoriesResponse> {
  const api = privateApi();
  const urlParams = toStringUrlParams(filters);

  const response = await api.get<IGetCategoriesResponse>(`/employee/category?${urlParams}`);
  const { data, total } = response.data;
  return { data, total };
}
