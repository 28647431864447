import { privateApi } from 'src/lib/api';
import { toStringUrlParams } from 'src/utils';
import { RolesResponse } from '../../types';

export async function searchRoles(searchR: string): Promise<RolesResponse> {
  const api = privateApi();
  const urlParams = toStringUrlParams({ searchR });

  const response = await api.get<RolesResponse>(`/employee/roles?${urlParams ? urlParams : ''}`);
  const { data, total } = response.data;
  return { data, total };
}
