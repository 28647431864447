import styles from './loading.module.scss';
import { cx } from 'src/utils';

export const Loading = () => {
  return (
    <div className={cx(styles.Loading)}>
      <div className={cx(styles['image-wrapper'])}>
        <img src="/alo-service.svg" alt="alo-service" />
      </div>
      <div className={cx(styles.loader)}></div>
    </div>
  );
};
