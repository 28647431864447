import { Button, Form, Grid, HandleLoading, Text } from 'src/components';
import { TextField } from 'src/components/controled';
import { RoleSchema } from '../../types';
import { FC } from 'src/types';
import { useGetPermissions, useAddRole, useGetRoleById, useEditRole } from '../../api';
import { Translator } from 'src/lib/localization';
import { rolesSchema } from '../../validation/rolesSchema';
import { useState } from 'react';
import { PermissionsCheckboxList } from '../PermissionsCheckboxList';
import { HandlePermissionsLoading } from '../HandlePermissionsLoading';
type RolesFormProps = {
  action: 'add' | 'edit';
  selectedRoleId?: string;
  onSuccess?: () => void;
};

export const RolesForm: FC<RolesFormProps> = ({ action, selectedRoleId, onSuccess }) => {
  const { mutateAsync: addRole, isLoading: addingRole, isSuccess } = useAddRole();
  const { mutateAsync: editRole, isLoading: editingRole } = useEditRole();
  const { data: permissions, isLoading: gettingPermissions } = useGetPermissions();
  const { data: selectedRole, isLoading: gettingRole } = selectedRoleId
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetRoleById({ roleId: selectedRoleId })
    : { data: null, isLoading: false };
  const [state, setState] = useState<any>([]);
  console.log('form state', state);

  return (
    <Form<RoleSchema, RoleSchema>
      onFormSubmit={async (data) => {
        data.guard_name = 'api';
        data.permissions = state.map((p: any) => ({
          permissio_id: p,
        }));
        console.log(data);
        action === 'add'
          ? addRole(data)
          : editRole({ roleId: selectedRole?.id as string, values: data });

        action === 'add' && onSuccess && onSuccess();
      }}
      options={{
        values:
          action === 'edit'
            ? {
                name: selectedRole?.name as any,
                guard_name: selectedRole?.guard_name as any,
                permissions: selectedRole?.permissions as any,
              }
            : undefined,
        defaultValues: {
          name: '',
          permissions: null as any,
          guard_name: 'api',
        },
      }}
      watch
      resetOnSuccess={action === 'add'}
    >
      {({ control, formState }) => (
        <Grid container rowGap={3} columnSpacing={3}>
          <Grid item xs={12} md={6}>
            <TextField name="name" control={control} label="name" />
          </Grid>
          <HandlePermissionsLoading isLoading={gettingRole}>
            <Grid item xs={12} md={12} lg={12}>
              <PermissionsCheckboxList
                permissions={permissions?.data}
                existingPermissions={action === 'edit' ? selectedRole?.permissions : null}
                onPermissionsChange={(selectedPermissions: any) => setState(selectedPermissions)}
              />
            </Grid>
          </HandlePermissionsLoading>
          <Grid item xs={12}>
            <Button
              type="submit"
              loading={action === 'add' ? addingRole : editingRole}
              variant="contained"
            >
              <Translator textKey={action} />
            </Button>
          </Grid>
        </Grid>
      )}
    </Form>
  );
};
