import { useAuth } from 'src/lib/auth';
import { FC } from 'src/types';

type PermissionProps = {
  permission: string;
  children: React.ReactNode;
};
export const Permission: FC<PermissionProps> = ({ permission, children }) => {
  const { user } = useAuth();
  if (user?.permissions.includes(permission)) {
    return <>{children}</>;
  }
  return null;
};
