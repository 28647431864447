import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UseMutationOptions } from 'src/lib/react-query';
import { notify } from 'src/lib/notify';
import { editEmployee } from './editEmployee';
import { IEmployee, IEmployeeSchema } from '../../types';
import { useLocalization } from 'src/lib/localization';

interface useEditEmployeeOptions {
  config?: UseMutationOptions<
    IEmployee,
    {
      id: string;
      employee: FormData;
    }
  >;
}

export const useEditEmployee = (options: useEditEmployeeOptions = {}) => {
  const { config } = options;
  const client = useQueryClient();
  const { translator } = useLocalization();

  const mutationFn = async ({ id, employee }: { id: string; employee: FormData }) => {
    try {
      const res = await editEmployee({ id, employee });

      client.invalidateQueries({
        predicate: (query: any) => query.queryKey.includes('employees'),
      });
      // client.setQueryData(['admins'], (prev: AdminsResponse = { total: 0, data: [] }) => {
      //   const oldData = [...prev.data];
      //   const newData = updateOne<IAdmins>(
      //     oldData,
      //     (item) => item.id === res.id,
      //     () => res,
      //   );

      //   return {
      //     total: prev.total,
      //     data: newData,
      //   };
      // });

      notify.success(translator('editSuccess'));
      return res;
    } catch (error: any) {
      notify.error(error?.response?.data?.errors[0]);
    }
  };

  return useMutation({
    mutationFn: mutationFn as any,
    mutationKey: ['employees'],
    ...config,
  });
};
