import { ILang, ILangKey, lang } from './lang';
import { ILangCode } from './types';

/**
 * Get language object for selected code.
 * @param {string} code code for language, `default is "en"`
 */
export function getLang(code: ILangCode = 'en'): ILang {
  return lang[code];
}

/**
 * Return translate for word by passing language code and text key for word.
 * @param  code  language code.
 * @param textKey text key for word.
 */
export function getLangTranslate(code: ILangCode, textKey: ILangKey) {
  return lang[code][textKey];
}

/**
 * Return current browser language
 */
export function getBrowserLanguage(): ILangCode {
  const language = navigator.language || navigator.language;
  const langCode = language ? language.split('-')[0] : 'en';
  return langCode as ILangCode;
}
