import { Response } from 'src/types';
import { IUser } from '../../types';
import { privateApi } from 'src/lib/api';

type SigninResponse = Response<Omit<IUser, 'token'>>;
export async function logOut() {
  const api = privateApi();
  const response = await api.post<SigninResponse>('/auth/logout');
  const { token, data } = response.data;
  return { token, ...data[0] };
}
