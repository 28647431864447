import './App.scss';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'react-toastify/dist/ReactToastify.css';
import { LicenseInfo } from '@mui/x-license-pro';
import { Providers } from 'src/providers';
import { setLocale } from 'yup';
import { yupLocale } from './lib/yup';

setLocale(yupLocale);

function App() {
  LicenseInfo.setLicenseKey(
    '186a4fab9c1d8ad1a6680236cc5071c9Tz01ODIzMCxFPTE3MDYwMDYzODgwMzQsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  );
  return (
    <div className="App light">
      <Providers />
    </div>
  );
}

export default App;
