import React, { useMemo } from 'react';
import { useLocalization } from '.';
import { ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { ToastContainer } from 'react-toastify';
import { FC } from 'src/types';
import { createTheme } from 'src/themes';

const cacheRtl = createCache({
  key: 'muirtl',
  prepend: true,
  stylisPlugins: [rtlPlugin],
});

type LanguageSettingProps = {
  children: React.ReactNode;
};

export const LanguageSetting: FC<LanguageSettingProps> = ({ children }) => {
  const { direction } = useLocalization();

  const theme = useMemo(
    () =>
      createTheme({
        direction,
      }),
    [direction],
  );

  return (
    <ThemeProvider theme={theme}>
      {direction === 'rtl' ? (
        <>
          <CacheProvider value={cacheRtl}>{children}</CacheProvider>
          <ToastContainer position={'top-left'} />
        </>
      ) : (
        <>{children}</>
      )}
      <ToastContainer />
    </ThemeProvider>
  );
};
