import { LocaleObject } from 'yup';

export const yupLocale: LocaleObject = {
  mixed: {
    // default: {
    //   key: 'validations.invalid',
    // },
    required: {
      key: 'validation_required',
    },
    notNull: {
      key: 'validation_required',
    },
    notType: ({ type }) => ({
      key: 'validations_invalidType',
      values: { type },
    }),
    oneOf: {
      key: 'validation_stringOneOf',
    },
  },
  string: {
    email: {
      key: 'validation_email',
    },
    min: ({ min }) => ({
      key: 'validation_min',
      values: { min },
    }),

    // no: {
    //   key: 'validation_required',
    // },
    // max: ({ max }) => ({
    //   key: 'validations.stringMax',
    //   values: { max },
    // }),
    // matches: ({ value }) => ({
    //   key: 'matches',
    //   values: { match: value },
    // }),
  },
  number: {
    moreThan: ({ value }) => ({
      key: 'validation_moreThan',
      values: { value },
    }),
  },
  array: {
    min: ({ value }) => ({
      key: 'validation_array_min',
      values: { value },
    }),
  },
};
