import { privateApi } from 'src/lib/api';
import { toStringUrlParams } from 'src/utils';
import { IGetCategoriesResponse } from '../../types';

export async function searchCategories(search: string): Promise<IGetCategoriesResponse> {
  const api = privateApi();
  const urlParams = toStringUrlParams({ search });

  const response = await api.get<IGetCategoriesResponse>(
    `/employee/category?parent_id=1&${urlParams ? urlParams : ''}`,
  );
  const { data, total } = response.data;
  return { data, total };
}
