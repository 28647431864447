import { Routes, Route } from 'react-router-dom';
import { lazyImport } from 'src/utils';
const { Feedbacks } = lazyImport(() => import('./pages'), 'Feedbacks');

export const FeedbackRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Feedbacks />} />
    </Routes>
  );
};
