import { Routes, Route } from 'react-router-dom';
import { lazyImport } from 'src/utils';
const { Notifications } = lazyImport(() => import('./pages/Notifications'), 'Notifications');

export const NotificationsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Notifications />} />
    </Routes>
  );
};
