import { FC } from 'src/types';
import { TextField, TextFieldProps } from '../TextField';
import { useState } from 'react';
import { IoEye } from 'react-icons/io5';
import { IoEyeOff } from 'react-icons/io5';
import { useTheme } from '@mui/material';
import { IconType } from 'react-icons';
import { Tooltip } from 'src/components/Tooltip';
import { useLocalization } from 'src/lib/localization';

export type PasswordFieldProps = Omit<TextFieldProps, 'StartIcon' | 'EndIcon' | 'type'>;

export const PasswordField: FC<PasswordFieldProps> = (props) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const { translator } = useLocalization();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const CustomIcon = (Icon: IconType) => (
    <Icon style={{ color: theme.palette.primary.main, fontSize: '1.3rem' }} />
  );

  return (
    <TextField
      {...props}
      type={showPassword ? 'text' : 'password'}
      StartIcon={() => {
        return (
          <Tooltip title={translator(showPassword ? 'hidePassword' : 'showPassword')}>
            <span style={{ cursor: 'pointer' }} onClick={handleClickShowPassword}>
              {showPassword ? CustomIcon(IoEyeOff) : CustomIcon(IoEye)}
            </span>
          </Tooltip>
        );
      }}
    />
  );
};
