import { ChipTypeMap } from '@mui/material';
import MuiAutocomplete, {
  AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material/Autocomplete';
import { InputAdornment, useTheme } from '@mui/material';
import { IconType } from 'react-icons';
import { ILangKey, useLocalization } from 'src/lib/localization';
import { TextField } from '../TextField';

export type AutocompleteProps<
  Value,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
> = Omit<
  MuiAutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>,
  'name' | 'label' | 'renderInput'
> & {
  name: string;
  label?: ILangKey;
  placeholder?: ILangKey;
  StartIcon?: IconType;
  EndIcon?: IconType;
};

// TODO: refactor
export function Autocomplete<
  Value,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>({
  name,
  label,
  placeholder,
  StartIcon,
  EndIcon,
  defaultValue,
  ...props
}: AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>) {
  const { translator } = useLocalization();
  const theme = useTheme();

  return (
    <MuiAutocomplete
      autoComplete
      includeInputInList
      filterSelectedOptions
      sx={{ width: '100%' }}
      autoHighlight
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            startAdornment: StartIcon && (
              <InputAdornment position="start">
                <StartIcon style={{ color: theme.palette.primary.main, fontSize: '1.3rem' }} />
              </InputAdornment>
            ),
            endAdornment: EndIcon && (
              <InputAdornment position="end">
                <EndIcon style={{ color: theme.palette.primary.main, fontSize: '1.3rem' }} />
              </InputAdornment>
            ),
            autoComplete: 'new-password',
          }}
          size="small"
          sx={{ width: '100%' }}
          label={label ? translator(label) : undefined}
          placeholder={placeholder ? translator(placeholder) : undefined}
        />
      )}
      {...props}
    />
  );
}
