import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-web';
import animation from 'src/animations/service.json';
interface UseLottieOptions {
  /**
   * id form element container to inject lottie inside.
   */
  elementId: string;
  /**
   * json file name without destenasion.
   * * the faile must be inside `src/animations` directory
   */
  filename: string;
}

export const useLottie = ({ elementId, filename }: UseLottieOptions) => {
  const [animation, setAnimation] = useState<any>(null);

  useEffect(() => {
    import(`../animations/${filename}.json`).then((value) => setAnimation(value));
  }, []);

  useEffect(() => {
    if (animation) {
      Lottie.loadAnimation({
        container: document.getElementById(elementId) as any,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animation,
      });
    }
  }, [animation]);
};
