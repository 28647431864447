import { createTheme, ThemeOptions } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    background: {
      default: '#ffffff',
      // paper: '#D8D8D8',
    },
    primary: {
      main: '#006da4',
    },
    secondary: {
      main: '#6b7280',
    },
    warning: {
      main: '#ff9800',
    },
    info: {
      main: '#0dcaf0',
    },
    success: {
      main: '#198754',
    },
    error: {
      main: '#dc3545',
    },
    common: {
      primary50: '#ffe0e5',
    },
  },
};
