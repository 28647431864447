import { Routes, Route } from 'react-router-dom';
import { lazyImport } from 'src/utils';
const { Banner } = lazyImport(() => import('./pages/Banner'), 'Banner');

export const BannerRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Banner />} />
    </Routes>
  );
};
