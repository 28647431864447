import { useQuery } from '@tanstack/react-query';
import { searchRoles } from './searchRoles';
import { UseQueryOptions } from 'src/lib/react-query';
import { RolesResponse } from '../../types';

interface UseSearchRolesOptions {
  searchR: string;
  config?: UseQueryOptions<RolesResponse>;
}

export const useSearchRoles = (options: UseSearchRolesOptions) => {
  const { searchR, config } = options;

  return useQuery({
    queryKey: ['search-orders', searchR],
    queryFn: () => searchRoles(searchR),
    keepPreviousData: true,
    ...config,
  });
};
