import { Response } from 'src/types';
import { IUser } from '../../types';
import { privateApi } from 'src/lib/api';

export async function updateUser(user: IUser): Promise<IUser> {
  const api = privateApi();
  const response = await api.put<Response<IUser>>(`/employee/user/`, user);
  const { data } = response.data;
  return data[0];
}
