import { privateApi } from 'src/lib/api';
import { toStringUrlParams } from 'src/utils';
import { EmployeeResponse } from '../../types';

export async function searchEmployees(search: string): Promise<EmployeeResponse> {
  const api = privateApi();
  const urlParams = toStringUrlParams({ search });

  const response = await api.get<EmployeeResponse>(`/employee/user?${urlParams ? urlParams : ''}`);
  const { data, total } = response.data;
  return { data, total };
}
