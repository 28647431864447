import Axios, { AxiosInstance } from 'axios';
import { CONFIG } from 'src/config';
import cookie from 'js-cookie';

interface Options {
  showNotify?: boolean;
}

export const privateApi = (options: Options = {}): AxiosInstance => {
  const { showNotify = false } = options;
  const accessToken = cookie.get('token');

  const axiosPrivate = Axios.create({
    baseURL: CONFIG.API_URL,
    headers: { 'Content-Type': 'application/json', Authorization: `Bearar ${accessToken}` },
  });

  axiosPrivate.interceptors.request.use(
    async (request) => {
      request.headers.Authorization = `Bearer ${accessToken}`;

      return request;
    },
    (error) => Promise.reject(error),
  );

  axiosPrivate.interceptors.response.use(
    (response) => response,
    async (error) => {
      // if (showNotify) {
      //   // const message = (error?.response?.data?.message || error?.message) as string;
      //   // notify(message, {
      //   //   type: 'error',
      //   // });
      // }

      return Promise.reject(error);
    },
  );
  return axiosPrivate;
};
