import React from 'react';
import { useAuth } from './useAuth';
import { FC } from 'src/types';
import { Navigate } from 'src/lib/router';

interface PrivateRouteProps {
  children: React.ReactNode;
}

export const PrivateRoute: FC<PrivateRouteProps> = ({ children }) => {
  const { user } = useAuth();

  if (user?.isAuthenticated) {
    return <>{children}</>;
  }

  return <Navigate to={'/auth/signin'} />;
};
