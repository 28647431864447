import { Routes, Route } from 'react-router-dom';
import { lazyImport } from 'src/utils';
const { Customers } = lazyImport(() => import('./pages'), 'Customers');
const { CustomerDetails } = lazyImport(() => import('./pages'), 'CustomerDetails');
export const CustomersRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Customers />} />
      <Route path="/:id" element={<CustomerDetails />} />
    </Routes>
  );
};
