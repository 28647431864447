import { UseQueryOptions } from 'src/lib/react-query';
import { getPermissions } from './getPermissions';
import { PermissionsResponse } from '../../types';
import { usePagination } from 'src/hooks';

interface UseGetPermissionsOptions {
  config?: UseQueryOptions<PermissionsResponse>;
}

export const useGetPermissions = (options: UseGetPermissionsOptions = {}) => {
  const { config } = options;

  return usePagination<PermissionsResponse>({
    queryKey: 'permissions',
    apiFn: () => getPermissions({ page: '0', skip: '0', take: '100', search: '' }),
    ...config,
  });
};
