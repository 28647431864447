import { useQuery } from '@tanstack/react-query';
import { searchEmployees } from './searchEmployees';
import { UseQueryOptions } from 'src/lib/react-query';
import { EmployeeResponse } from '../../types';

interface UseSearchEmployeesOptions {
  search: string;
  config?: UseQueryOptions<EmployeeResponse>;
}

export const useSearchEmployees = (options: UseSearchEmployeesOptions) => {
  const { search, config } = options;

  return useQuery({
    queryKey: ['search-employees', search],
    queryFn: () => searchEmployees(search),
    keepPreviousData: true,
    ...config,
  });
};
