import { FC } from 'react';
import { Controller, useFormState, useWatch } from 'react-hook-form';
import { ILangKey, useLocalization } from 'src/lib/localization';
import { Button } from 'src/components/Button';
import { useRef, useState, useEffect } from 'react';
import { Box } from 'src/components/Box';

export type UploadImageProps = {
  name: string;
  control: any;
  label?: ILangKey;
};

export const UploadImage: FC<UploadImageProps> = ({ name, control, label, ...props }) => {
  const attachment = useWatch({
    control,
    name: name, // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: null, // default value before the render
  });
  const { translator } = useLocalization();
  const [imagePreview, setImagePreview] = useState(null);
  const ref = useRef<any>(null);
  useEffect(() => {
    if (attachment && typeof attachment === 'object') {
      const url = URL.createObjectURL(attachment);
      setImagePreview(url as any);
    } else {
      setImagePreview(null);
    }
    console.log('Selected File:', attachment);
  }, [attachment]);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ...rest } }) => (
        <>
          {imagePreview && (
            <Box component={'img'} src={imagePreview} sx={{ width: '250px', pb: '8px' }}></Box>
          )}
          {value && !imagePreview && (
            <Box component={'img'} src={value} sx={{ width: '250px', pb: '8px' }}></Box>
          )}
          <Button
            variant="contained"
            onClick={() => {
              ref.current?.click();
            }}
          >
            {translator('uploadImage')}
            <input
              type="file"
              onChange={(event) => {
                const file = event.target.files?.[0];
                onChange(file);
                console.log('======================');
              }}
              {...rest}
              style={{ display: 'none' }}
              ref={ref}
            />
          </Button>
        </>
      )}
    />
  );
};
