import { Suspense } from 'react';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'src/lib/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { isDevelopment } from 'src/utils';
import { MainRoutes } from 'src/routes';
import { NotifyProvider } from 'src/lib/notify';
import { LanguageSetting, LocalizationProvider } from 'src/lib/localization';
import { CheckAuth } from 'src/lib/auth';
import { Loading } from 'src/components/Loading';

export const Providers = () => {
  return (
    <Suspense fallback={<Loading />}>
      <StyledEngineProvider injectFirst>
        <QueryClientProvider client={queryClient}>
          <Router>
            <CheckAuth>
              <LocalizationProvider>
                <LanguageSetting>
                  <CssBaseline />
                  <MainRoutes />
                  <NotifyProvider />
                </LanguageSetting>
              </LocalizationProvider>
            </CheckAuth>
          </Router>
          {isDevelopment() ? (
            <ReactQueryDevtools
              buttonPosition="bottom-left"
              client={queryClient as any}
              initialIsOpen={false}
              position="bottom"
            />
          ) : null}
        </QueryClientProvider>
      </StyledEngineProvider>
    </Suspense>
  );
};
