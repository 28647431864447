import { Route, Routes } from 'react-router-dom';
import { lazyImport } from 'src/utils';
const { SignIn } = lazyImport(() => import('../pages'), 'SignIn');

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="signIn" element={<SignIn />} />
    </Routes>
  );
};
